import React from 'react'
import Back from '../back/Back'
import EmprunteurCard from './EmprunteurCard'
import EmprunteurTitle from './EmprunteurTitle'








const Emprunteur =()=> {
    return(<>

    <Back title='ASSURANCE EMPRUNTEUR' />
<EmprunteurTitle/>
 <EmprunteurCard/>

 

    </>)
}
export default Emprunteur