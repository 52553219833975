import React from "react"
import { Link } from "react-router-dom"




const FreelanceCard = () => {
  return (
    <>
      <section className='conducteur'>
      <div className='container'>
      <h2>Pourquoi souscrire à une complémentaire santé quand on est freelance ?</h2>
         
         <p>En tant que freelance, vous êtes soumis au régime des Travailleurs Non Salariés (TNS) de l’Assurance Maladie. <span style={{fontWeight: "600"}}>Malheureusement, vous bénéficiez d’une protection sociale et d’une couverture santé moins avantageuse que celle des salariés :</span> si le taux de remboursement des soins est le même pour tous, 
         vos garanties sont moindres lorsque vous devez faire face à un accident du travail ou un arrêt maladie.</p></div>
          <div className='container flexSBf'>

          <div className='right1 row'>


          <h3 ><br/>pourquoi vous avez tout intérêt à souscrire <Link to='/MutuelleTNSIndépendant' >une mutuelle TNS Indépendant</Link>, qui vous offre :</h3>
                <br/>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>de meilleurs remboursements de frais de santé ou d’hospitalisation </span> (la prise en compte des soins optiques est par exemple essentielle, la majorité des freelances travaillant sur écran toute la journée…),</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>le tiers payant  </span>  (vous n’avez plus à faire l’avance lorsque vous allez voir le médecin ou achetez des médicaments),</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>un complément de revenus  </span> si vous devez interrompre votre activité le temps d’une convalescence.</p>

      
            </div>
          <div className='left1 row' >
            <img src='./images/free.jpg' alt='Freelance' />
          </div>

          </div>

  <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/free1.jpg' alt='Freelance' />
          </div>
          <div className='right row'>
      <h2>
      Comment trouver la meilleure complémentaire santé pour freelance ?</h2>
         
         <br/> <p>Comme on vient de le voir, si vous faites l’impasse sur la mutuelle, il y a fort à parier 
         que vos revenus et votre productivité en pâtissent un jour… Toutefois, 
         trouver une bonne assurance complémentaire santé n’est pas simple tant les offres sont multiples et complexes.</p>
             <h3 ><br/>Le recours à un courtier en assurance est la solution la moins chronophage et la plus efficace.</h3>
             <p >Chez Azur Conseil, nous vous aidons à faire le tri et vous proposons<Link to='/MutuelleTNSIndépendant'>la mutuelle santé la plus adaptée 
             à votre situation de travailleur non salarié</Link>  et à votre budget. Nous faisons le bilan de vos couvertures<Link to="/professionnelle"> (responsabilité civile professionnelle
              </Link>  , <Link to="/AutreProduitsAssurance"> prévoyance,</Link> etc.) 
             et vous conseillons spécifiquement en fonction de votre statut et de vos besoins de freelance :<span style={{color:"#000000"}}> pour un accompagnement complet !</span></p>
            </div>
          </div>
          <div className='container '>
  
  <div className='title' style={{marginTop:"0px"}}>


 <div className="box">
                    
                    <div className="text">
    
                        <p>Vous hésitez encore à prendre une couverture santé ? Sachez qu’afin d’encourager les freelances à se protéger, La Loi Madelin (1994) prévoit que les travailleurs indépendants puissent déduire fiscalement leurs frais de mutuelle santé.
                 </p></div></div>
                 <hr class="solidDivider"/>
                 <br/>
                 <h4>Pour obtenir un devis pour une complémentaire santé ou pour toute question relative aux obligations liées à votre activité, vous pouvez vous adresser au Cabinet Azur Conseil.</h4>
    
    <p>
    Nos conseillers experts, basés en France, à Cagne sur Mer (06), sont à votre disposition et à votre écoute pour répondre à toutes vos demandes.

<br/><br/>
</p>
<Link to="/contact">
 <button className='btn-orange'>
CONTACTER VOTRE EXPERT COMPLÉMENTAIRE SANTÉ POUR FREELANCE<i className='fa fa-long-arrow-alt-right'></i>
      </button></Link>
  </div>

  </div>
      </section>

    </>
  )
}

export default FreelanceCard