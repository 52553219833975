import React from "react"
import Back from "../back/Back"
import { useState, useEffect } from 'react';
import { blog } from "../data/Data"
import {  Link, useParams } from 'react-router-dom';

const BlogDetail = () => {

    const { id } = useParams();




  return (
    <> <Back title='ACTUALITÉS' />
   
   
      
        {blog.map((val) => (   <section className='AssuranceHome'>{
              val.id == id &&(  <div className='container'>
           
          <div className='title'>
            <h2>{val.title}</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}></h4>
         <p style={{padding: "20px"}}>
    <a>{val.date}</a>
<br/>
 {val.desc}

         </p>
          </div>
          <div className='container'>
          <div >
            <img src={val.cover} alt='blog' />
          </div>
          <div>
          <div dangerouslySetInnerHTML={ {__html: val.text} } />
  <p>Source : <Link to={val.source} >{val.source}</Link></p>

            </div>
          </div>
          </div>) }</section> ))}
     
  
    </>
  )
}


export default BlogDetail