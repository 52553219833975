import React from "react"

import "./mutuelle.css"





const MutuelleTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Trouver la meilleure mutuelle santé</h2>
            <hr class="solid"/>
            <br/>
            <h4>Vous ne savez pas comment choisir votre mutuelle santé ?</h4>
         <p style={{padding: "20px"}}>
         Azur Conseil vous accompagne et compare pour vous les tarifs et les garanties de nombreuses complémentaires, 
         afin de vous offrir des<a style={{color:"#3baa36"}}> tarifs intéressants et une protection optimale</a> , adaptée à votre situation. 
         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default MutuelleTitle