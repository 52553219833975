import React from "react"
import BlogCard from "./BlogCard"





const BlogA = () => {
  return (
    <>
      <section className='blog padding'>
        <div className=' grid2'>
          <BlogCard />
        </div>
      </section>
    </>
  )
}

export default BlogA