import React from 'react'
import Back from '../back/Back'
import AutreProduitCard from './AutreProduitCard'
import AutreProduitTitle from './AutreProduitTitle'









const AutreProduit =()=> {
    return(<>

    <Back title="AUTRES PRODUITS D'ASSURANCE" />
    <AutreProduitTitle/>
<AutreProduitCard/>

 

    </>)
}
export default AutreProduit