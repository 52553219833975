import React from 'react'
import Back from '../back/Back'
import Back3 from '../back/Back3'
import ProfessionnelleCard from './ProfessionnelleCard'
import ProfessionnelleTitle from './ProfessionnelTitle'




const Professionnelle =()=> {
    return(<>

    <Back3 title='Assurance professionnelle' />
    <ProfessionnelleTitle/>
    <ProfessionnelleCard/>
  
  

    </>)
}
export default Professionnelle