import React from "react"
import { Link } from "react-router-dom"





const ProfessionnelleCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/pro.webp' alt='Assurance professionnelle ' />
          </div>
          <div className='right1 row'>
      <h2>Une RC Pro ou responsabilité civile professionnelle, qu’est-ce que c’est ?</h2>
         
            <p> <br/> <p >La RC Pro est le contrat d’assurance indispensable à toute société. <a style={{color:"#3baa36"}}>Elle protège les salariés et l’entreprise dès que leur responsabilité ou celle du matériel est engagée, dans le cas de dommages corporels, 
            matériels ou immatériels causés à autrui.</a> Concrètement, la responsabilité civile professionnelle pallie les conséquences financières de ces dommages (indemnités aux victimes notamment).
<br/>
<span style={{fontWeight: "500"}}>
La RC Pro est obligatoire pour diverses professions réglementées</span> (médecin, avocat, architecte, agent immobilier, etc.) et fortement conseillée pour l’ensemble des professionnels. Vous en passer reviendrait en effet à exposer inutilement votre société.</p></p>
             
            </div>
          </div>
          <div className='container'>
          <div className="box">
                            
                            <div className="text">
            
                                <p><Link to="/about">Azur Conseils</Link> vous propose un contrat RC Pro / Responsabilité civile professionnelle adapté à votre activité professionnelle et ses obligations. 
                         </p></div></div>
       
       <h2 style={{marginTop: "30px"}}>Assurance professionnelle et artisan du bâtiment : la garantie décennale</h2> 
  
<br/>
<p >Vous exercez dans le bâtiment ? Vous êtes dans l’obligation de souscrire une assurance décennale, que vous soyez artisan ou auto-entrepreneur. Vos clients peuvent d’ailleurs vous demander une preuve de cette couverture obligatoire avant la signature d’un devis.
<br/>

Pourquoi l’assurance décennale est obligatoire ? Parce que vous engagez votre responsabilité durant les 10 ans qui suivent la réalisation de l’ouvrage / la fin du chantier. <a style={{color:"#3baa36"}}>L’assurance décennale, communément appelée garantie décennale, assure le risque de dommage ou sinistre sur le bâtiment.</a>

 </p>

                               </div>
                               <div className='container '>
  
          <div className='title' style={{marginTop:"0px"}}>
        
         <div className="box">
                         
                            <div className="text">
                      
                                <p>Azur Conseils recherche pour vous le meilleur contrat d’assurance décennale.
                         </p></div></div>
                         <hr class="solidDivider"/>
                         <br/>
            
            <p>Nos conseillers en assurance professionnelle sont basés en France, à Cagne sur Mer dans les Alpes Maritimes (06). Ils sont à votre disposition et vous proposent un rendez-vous téléphonique pour définir vos attentes et vos besoins en fonction des obligations liées à votre activité professionnelle.<br/><br/>
     </p>
     <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE COURTIER EN MUTUELLE SANTÉ <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
    
      </section>

    </>
  )
}

export default ProfessionnelleCard