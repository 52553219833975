import React from "react"
import { Link } from "react-router-dom"

import "./assurance.css"
import Service from "./Services"



const AssuranceCard = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container'>
       
        <h2>Choisir Azur Conseil pour trouver son assurance</h2>
        <br/>

 <p style={{fontWeight: "500"}}>En tant que courtier en assurance,<Link to='/about'> Azur Conseil </Link> fait office de comparateur d’assurance.
Nous échangeons longuement avec vous quant à votre situation, vos besoins et vos contraintes puis nous nous chargeons de toutes les démarches auprès de nos partenaires. Nous vous proposons ensuite l’offre la plus avantageuse.</p>
<div className="boxAssu">
                            
                            <div className="textAssu">
            
                                <p>Vous êtes considéré comme profil à risque aggravé ? Aucun problème, c’est notre spécialité ! Nos produits d’assurance phare :</p>
                                <div className='container flexSB'>
                                <div className='left row'>
                              
                                <div class="list-type3 ">
<ol>
<li><Link to="/AssuranceAutoConducteurSansAntécédents">Conducteur sans antécédents ou non assuré </Link></li>
<li><Link to="/AssuranceAutoConducteurRésilié">Conducteur résilié</Link></li>
<li><Link to="/AssuranceMotoScooter">Moto / scooter</Link></li>

</ol>
</div>
          </div>
          <div className='right row'>            
            
<div class="list-type3">
<ol>
<li><Link to="/AssuranceHabitation">Habitation</Link></li>
<li><Link to="/AssuranceEmprunteur">Emprunteur</Link></li>
<li><Link to="/AutreProduitsAssurance">Autres assurances</Link></li>
</ol>
</div>
</div> ,
</div>
                                
  
                                
                                </div></div>   </div>
       
                                <div className='container  flexSBf'>
          <div className='left1 row'>
            <img src='./images/assurance.jpg' alt='Assurance et garanties' />
          </div>
          <div className='right1 row'>
            <br/>
      <h2>Pourquoi faire appel à un courtier en assurance ?</h2>
         
            <p style={{fontSize: "17px"}}> -<a> Parce que lorsque vous êtes au pied du mur, sans assurance à la suite d’une résiliation compagnie,</a> le courtier ne vous tourne pas le dos et sait trouver le produit qui vous assurera au mieux.

 <br/>

-<a> Parce que la loi Hamon vous permet de changer de compagnie après un an de protection de votre habitation,</a> il est toujours intéressant de trouver un produit d’assurance moins cher ! Un courtier en assurance comme Azur Conseil réalise pour vous une étude comparative gratuite sans engagement afin de déterminer s’il est dans votre intérêt de rester assuré auprès de la même compagnie ou non.

<br/>

- <a>Parce qu’un courtier vous conseille </a> quant au type de protection dont vous avez besoin en fonction de votre situation de famille, votre activité, etc.</p>
            </div>
            
          </div>
          <div className='container  '>
          <div className="box">
                            
                            <div className="text">
            
                                <p>Notre cabinet de courtier vous fait gagner du temps et de l’argent,    <a style={{color:"#3baa36"}}>quelle que soit votre situation !</a>
                         </p></div></div>
          <div className='title' style={{marginTop:"0px"}}>
         
  
         <p style={{padding: "20px"}}>
         Vous avez des questions sur les garanties indispensables ?  <Link to="/professionnelle">Sur l’assurance professionnelle </Link>? <Link to="/mutuelle"> La couverture santé</Link> ? Vous aimeriez une simulation détaillée avant de vous décider ?

Nous sommes toujours à votre écoute et mettons à votre disposition des conseillers expérimentés et implantés en France, à Cagne sur Mer (06).
         </p>
         <Link to="/contact">
         <button className='btn-orange'>
         CONTACTER AZUR CONSEIL,COURTIER EN ASSURANCE <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>

            <Service/>

          
      </section>

    </>
  )
}

export default AssuranceCard