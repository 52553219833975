import React from "react"







const FreelanceTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Freelance : trouver une bonne complémentaire santé</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Être freelance, c’est être libre de choisir ses horaires, ses clients, ses conditions de travail. Mais c’est aussi vivre dans l’incertitude en cas de maladie.</h4>
         <p style={{padding: "20px"}}>
         Le régime social des freelances étant moins avantageux que celui des salariés, il est important de souscrire à une complémentaire santé, afin de bénéficier d’une protection digne de ce nom. Seulement voilà, une complémentaire santé peut coûter cher si l’on ne sait pas où chercher. 


       
         <h4 style={{fontSize:"18px", color:"#ee8a00", paddingTop:"0px"}}>Azur Conseil est là pour accompagner chaque freelance dans sa recherche d’une complémentaire santé adaptée à ses besoins et son activité.</h4>
         </p> </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default FreelanceTitle