import React from "react"
import { Link } from "react-router-dom"





const AutreProduitCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>
            <img src='./images/juri.jpg' alt='L’assurance protection juridique vie privée ' />
          </div>
          <div className='right row'>
      <h2><br/>
L’assurance protection juridique vie privée : à quoi ça sert ?</h2>
         
            <p> <br/> <p >L’assurance protection juridique vie privée vous permet de bénéficier d’un accompagnement spécifique lors d’une procédure judiciaire entamée dans le cadre d’un litige.
            <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Assistance juridique  </span></p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Prise en charge des frais d’avocat, d’expertise, etc. </span> </p>
                     <br/> Elle fait l’objet d’un contrat propre : ses garanties et tarifs dépendent de l’assureur.</p>
                    </p>
           
            </div>
          </div>
          <div className='container flexSB'>
          <div className='right1 row'>
      <h2>La garantie obsèques : financer ses propres funérailles</h2>
         
            <p > <br/> Souscrire une assurance obsèques, c’est éviter à sa famille d’avoir à assumer le prix des funérailles. Concrètement, le capital est versé aux bénéficiaires
             ou à une société de pompe funèbres prédéfinie, au moment du décès de l’assuré.
     <br/> 
<br/>
Comme pour<Link to="/assurance"> tout contrat d’assurance,</Link> les formules (financement ou financement + organisation des obsèques par exemple), de même que les types de cotisation (unique, temporaire ou viagère), varient en fonction des assureurs, banques et mutuelles qui les proposent.
<br/><br/><a style={{color:"#3baa36"}}> C’est pourquoi il convient de prendre garde aux contrats opaques ou inadaptés à votre situation.</a></p>
            </div>
          <div className='left1 row' >
            <img src='./images/assurance.jpg' alt='La garantie obsèques' />
          </div>

          </div>
          <div className='container flexSB'>
          <div className='left row'>
            <img src='./images/mutuelle.webp' alt='La prévoyance' />
          </div>
          <div className='right row'>
      <h2><br/>La prévoyance : palier les conséquences financières liées aux accidents de la vie</h2>
         
            <p> <br/> <p >
Une assurance prévoyance vous assure de maintenir votre niveau de revenu en cas d’accident de la vie. Comment cela fonctionne ?
      
                  <br/>   <br/> <a style={{color:"#3baa36"}}> Dans un premier temps vous déterminez le niveau de prestation et donc le montant de votre cotisation mensuelle.</a> Si l’accident se produit (maladie, décès, etc.), vous percevez un capital ou une indemnité journalière, selon le contrat que vous aurez souscrit.</p>
                    </p>
           
            </div>
          </div>
          <div className='container flexSB'>
          <div className='right1 row'>
      <h2><br/>Assurance chien et chat : protéger son animal</h2>
         
            <p > <br/> Cette assurance dédiée à la santé de votre animal (chien de 1er / 2e catégorie et chat) vous permet de lui offrir des soins adaptés en cas de maladie ou d’accident, sans vous soucier du montant des frais de vétérinaire.
     <br/> 
<br/>
S’appuyer sur un courtier comme Azur Conseil,<a style={{color:"#3baa36"}}>pour choisir le meilleur contrat protection juridique, garantie obsèques, prévoyance, protection pour chien et chat,
</a> vous assure un accompagnement complet et des propositions personnalisées.</p>
            </div>
          <div className='left1 row' >
            <img src='./images/animaux.jpg' alt='Assurance chien et chat' />
          </div>

          </div>
          <div className='container flexSB'>
          <div className='title'>
          <hr class="solidDivider" style={{marginTop: "10px"}}/>  
          <br/> 
         <p>
         N’hésitez pas à contacter Azur Conseil, basé à Cagne sur Mer (06). Nos experts vous en disent plus sur les limites de l’assurance protection juridique, garantie obsèques, prévoyance, chien et chat, etc. Ils vous proposent rapidement un rendez-vous téléphonique.
         </p><br/>
         
         <Link to="/contact">  
         <button className='btn-orange'>
      CONTACTER VOTRE COURTIER EN ASSURANCE PROTECTION JURIDIQUE VIE PRIVÉE/GARANTIE OBSÉQUES/PRÉVOYANCE/CHIEN ET CHAT <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>

          </div>
  
      </section>

    </>
  )
}

export default AutreProduitCard