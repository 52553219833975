import React from 'react'
import Back from '../back/Back'
import AssuranceCard from './AssuranceCard'
import AssuranceTitle from './AssuranceTitle'


const Assurance =()=> {
    return(<>

    <Back title='Assurance' />
    <AssuranceTitle/>
    <AssuranceCard/>

    </>)
}
export default Assurance