import React from "react"
import { Link } from "react-router-dom"




const SansAntecedentsCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/condu.jpg' alt='conducteur sans antécédents' />
          </div>
          <div className='right1 row'>
      <h2>Nos formules Assurance auto conducteur sans antécédents</h2>
         
            <p >  <br/> <p >Inutile d’avoir un bonus pour prétendre à une assurance auto intéressante. Chaque conducteur, sans antécédents ou non assuré depuis plus de 3 ans, peut bénéficier de garanties essentielles à un tarif compétitif, selon ses besoins.
</p></p>
<p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Formule au tiers : </span> l’indispensable, incluant la responsabilité civile obligatoire + assistance à 0 km. Elle est parfaite pour une voiture ayant déjà bien vécu.</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Formule au tiers étendu : </span>   ce produit couvre en plus le vol et l’incendie de votre voiture, garanties conseillées pour une voiture récente.</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Formule tous risques :  </span>  vous êtes couvert en cas d’accident responsable ou non responsable, ainsi que votre véhicule. A privilégier si vous avez une voiture neuve.</p>
            </div>
          </div>
          <div className='container'>
       
      <br/>
  

<p style={{fontWeight: "500"}}>Vous ne savez pas quelle formule d’assurance auto conducteur sans antécédents choisir ? N’hésitez pas à nous contacter, une fois que nous vous connaitrons mieux, nous pourrons déterminer avec vous le produit d’assurance auto le plus adéquat.</p>
  </div>
                               <div className='container '>

                               <hr class="solidDivider" style={{marginTop: "10px"}}/>
  <br/>
          <div className='title' style={{marginTop:"0px"}}>
          <h4> 

Vous recherchez un contrat d’assurance auto conducteur sans antécédents / non assuré pas cher ? Nous faisons en sorte de vous le trouver !</h4>
  
         <p style={{padding: "20px"}}>
         Nos conseillers sont à votre écoute ! Vous pouvez communiquer avec eux par téléphone ou via notre site, ils vous répondent sans frais car ils sont basés en France (Cagne sur Mer, Alpes Maritimes),<Link to="/assurance"> quel que soit le produit d’assurance qui vous intéresse.</Link> 
         </p>
  
                     
         <Link to="/contact">
         <button className='btn-orange'>
        CONTACTER VOTRE SPÉCIALISTE ASSURANCE AUTO CONDUCTEUR SANS ANTÉCÉDANTS / NON ASSURÉ <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
        
      </section>

    </>
  )
}

export default SansAntecedentsCard