import React from 'react'
import Back from '../back/Back'
import HabitationCard from './HabitationCard'
import HabitationTitle from './HabitationTitle'







const Habitation =()=> {
    return(<>

    <Back title='ASSURANCE HABITATION' />
    <HabitationTitle/>
    <HabitationCard/>
 

 

    </>)
}
export default Habitation