import React from 'react'
import Back from '../back/Back'
import Back6 from '../back/Back6'



import SansAntecedentsCard from './SansAntecedentsCard'
import SansAntecedentsTitle from './SansAntecedentsTitle'




const SansAntecedents =()=> {
    return(<>

    <Back6 title='ASSURANCE AUTO CONDUCTEUR SANS ANTÉCÉDENTS' />

  <SansAntecedentsTitle/>
  <SansAntecedentsCard/>

    </>)
}
export default SansAntecedents