import React from "react"


const ResilieTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Assurance auto conducteur résilié : une solution personnalisée</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Votre compagnie d’assurance a décidé de résilier votre contrat d’assurance auto, pour non-paiement de vos cotisations dans les 30 jours légaux par exemple ? Vous avez fait l’erreur de faire une fausse déclaration ? Vous serez sous peu sans couverture, 
            avec un malus important, et devez en retrouver une rapidement, sous peine de ne pas être en conformité avec la loi.</h4>
         <p style={{padding: "20px"}}>
        
      Azur Conseil vous épaule dans cette recherche et trouve pour vous une solution personnalisée, avec une    <a style={{color:"#3baa36"}}> majoration de prime réduite.</a>

 

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default ResilieTitle