import React from "react"
import { Link } from "react-router-dom"




const ConducteurCard = () => {
  return (
    <>
      <section className='conducteur'>
      <div className='container'>
      <h2>Un conducteur malussé, qu’est-ce que c’est ?</h2>
         
         <p > Un conducteur malussé est un conducteur responsable d’un ou plusieurs accidents, qui voit appliqué
              à sa prime d’assurance un coefficient négatif, qui en augmente le tarif en guise de punition.</p></div>
          <div className='container flexSBf'>

          <div className='right1 row'>


          <h3 ><br/>Est-ce que le coefficient varie d’un assureur à un autre ? Non, il est le même pour tous, régi par le Code des assurances:</h3>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au premier accident dont vous êtes responsable : </span> coefficient 1.25, soit une augmentation de 25 % de votre cotisation</p>
                      <p ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au deuxième : </span>  coefficient 1.56, soit une augmentation de 56 % de votre cotisation</p>
                      <p ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au troisième : </span>  coefficient 1.95, soit une augmentation de 95 % de votre cotisation</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au quatrième : </span>   coefficient 2.44, soit une augmentation de 144 % de votre cotisation</p>
                      <p ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au cinquième : </span>   coefficient 3.05, soit une augmentation de 205 % de votre cotisation</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au sixième : </span>    coefficient 3.50, soit une augmentation de 250 % de votre cotisation</p>
                      <h3 >Le coefficient baisse de lui-même à hauteur de 5 % par année de conduite responsable.</h3>
            </div>
          <div className='left1 row' >
            <img src='./images/conducteur.jpg' alt='conducteur malussé,' />
          </div>

          </div>
          <div className='container '>
  
  <div className='title' style={{marginTop:"0px"}}>

 <div className="box">
                    
                    <div className="text">
    
                        <p>S’il enchaine les accidents, les bris de glace, les retraits de permis pour vitesse supérieure à la limite autorisée ou alcoolémie et les difficultés de paiement, un conducteur malussé va rapidement devenir un profil à risques et aura des difficultés à conserver son assurance auto, certaines compagnies d’assurance préférant résilier leur contrat pour aggravation de risques.
                 </p></div></div>
               
  </div>

  </div>
  <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/cond.jpg' alt='Un conducteur malussé,' />
          </div>
          <div className='right row'>
      <h2>
Assurer sa voiture quand on est malussé : comment faire ?</h2>
         
         <br/> <p >Vous avez un malus conséquent, votre prime d’assurance est élevée.
             Si vous souhaitez changer de compagnie pour vous assurer moins cher, si vous ne trouvez pas d’assureur vous proposant un 
             tarif et une protection avantageuse, vous pouvez passer par un courtier en assurance.</p>
             <h3 ><br/>Azur Conseil, spécialisé dans les profils à risque aggravé, offre à tout conducteur malussé d’assurer sa voiture quels que soient sa situation et son passif de malus.</h3>
             <h2><br/>Pourquoi assurer sa voiture quand on est malussé ?</h2>
            </div>
          </div>
          <div className='container '>
  
  <div className='title' style={{marginTop:"0px"}}>


 <p style={{padding: "20px"}}>
 Tout simplement parce qu’assurer sa voiture est obligatoire. Depuis le 1er janvier 2019, gendarmes et policiers de la route ont accès au Fichier des Véhicules Assurés (FVA) et peuvent vous verbaliser si vous n’êtes pas en règle.

 </p>
 <div className="box">
                    
                    <div className="text">
    
                        <p>Azur Conseil, dont le siège est à Cagne sur Mer (06), accompagne également<Link to="/AssuranceAutoConducteurRésilié"> les conducteurs résiliés afin qu’ils bénéficient d’une assurance auto, obligatoire.</Link>
                 </p></div></div>
                 <hr class="solidDivider"/>
             
    <br/>
    <p>Vous souhaitez un avis ? Un devis pour assurer votre voiture en tant que conducteur malussé ?

N’hésitez pas à nous contacter !<br/><br/>
</p>
<Link to="/contact">
 <button className='btn-orange'>
CONTACTER AZUR CONSEIL? COURTIER EN ASSURANCE<i className='fa fa-long-arrow-alt-right'></i>
      </button></Link>
  </div>

  </div>
      </section>

    </>
  )
}

export default ConducteurCard