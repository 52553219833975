import React from "react"


const MotoTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Contrat d’assurance moto / scooter risque aggravé pas cher</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous avez été résilié par votre précédente compagnie d’assurance ? Vous êtes malussé ? Vous avez perdu l’ensemble des points de votre permis ?</h4>
         <p style={{padding: "20px"}}>
        
         Vous souhaitez trouver un contrat <a style={{color:"#3baa36"}}> d’assurance moto / scooter risque aggravé adapté à votre quotidien et pas cher</a> ? Azur Conseil peut vous aider ! Nous vous offrons un service en ligne ou par téléphone, quels que soient vos attentes.

 

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default MotoTitle