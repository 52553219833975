import React, { useState } from "react"
import Back from "../back/Back"
import emailjs from 'emailjs-com';

const Result = () =>{
    return(
        <p>successfully</p>
    )
}


const DevisE = () => {
    const [result ,showResult] = useState(false)
  
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [ville, setVille] = useState("");
    const [message, setMessage] = useState("");
    const [codepostal, setCodepostal] = useState("");
    const [Type, setType] = useState("");
    const [adresse, setAdresse] = useState("");
  
    const [NombreP, setNombreP] = useState("");
    const [MontantP, setMontantP] = useState("");
    const [Taux, setTaux] = useState("");
    const [TTaux, setTTaux] = useState("");
      const handleChange1 = event => {
        console.log(event.target.value);
        setType(event.target.value);
      };
      const handleChange = event => {
        console.log(event.target.value);
        setTTaux(event.target.value);
      };
 
 const emailContent ={
    FName: FName,
    LName: LName,
    phone: phone,
    email: email,
    ville: ville,
    codepostal: codepostal,
    adresse: adresse,
    NombreP: NombreP,
    MontantP: MontantP,
    message: message,
    Type: Type,
    Taux: Taux,
    TTaux: TTaux,

 };
    const sendEmail = (e) => {
        e.preventDefault();
      
        emailjs.send('service_lgygw4b','template_j3gbenu',emailContent,'vXsPUqEyTvSoTsdj_').then((result)=> {
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
    }
    setTimeout(()=>{
        showResult(false);
    }, 5000)

  return (
    <>
    
      <section className='contacts padding' style={{marginTop:"-40px"}}>
        <div className='container shadow' >
       

            <form onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Nom *' name="FName" className="input" onChange={(event) => {setFName(event.target.value)}} required/>
                <input type='text' placeholder='Prénom *' name="LName" className="input"  onChange={(event) => {setLName(event.target.value)}} required />
              </div>
              <input type='text' placeholder='Téléphone *'  name="phone" className="input" onChange={(event) => {setPhone(event.target.value)}}  required />
              <input type='email'placeholder='Email *'  name="email"className="input" onChange={(event) => {setEmail(event.target.value)}}  required  />
              <input type='text'placeholder='Adresse *'  name="adresse" className="input" onChange={(event) => {setAdresse(event.target.value)}} required  />
              <div className='flexSB'>
                <input type='text' placeholder='Code postal' name="codepostal" className="input" onChange={(event) => {setCodepostal(event.target.value)}} required />
                <input type='text' placeholder='Ville' name="ville" className="input" onChange={(event) => {setVille(event.target.value)}} required />
              </div>
              <input type='text' placeholder='Nombre de prêt(s) *'  name="NombreP" className="input" onChange={(event) => {setNombreP(event.target.value)}}  required />
              
              <input type='text' placeholder='Montant du prêt *'  name="MontantP" className="input" onChange={(event) => {setMontantP(event.target.value)}}  required />

              <div className='flexSB'>
<p>Type d'emprunt *  :</p>
<select name='Type' onChange={handleChange1} style={{width:"650px"}} >
<option value='Faites votre choix'>Faites votre choix</option>
  <option value='Infine'>Infine</option>
  <option value="Amortissable">Amortissable</option>
  <option value="Relais">Relais</option>
  <option value="Autre(s)">Autre(s)</option>

  
</select></div>
<input type='text' placeholder='Taux Prêt *' className="input"  name="Taux" onChange={(event) => {setTaux(event.target.value)}}  required />
<div className='flexSB'>
<p>Type de taux * :</p>
<select name='Type' onChange={handleChange} style={{width:"650px"}} >
<option value='Faites votre choix'>Faites votre choix</option>
  <option value='Fixe'>Fixe</option>
  <option value="Variable">Variable</option>
 

  
</select></div>
              <textarea type='text' placeholder='Des précisions ?' name="message"  onChange={(event) => {setMessage(event.target.value)}}  required />
            
              <button className='primary-btn'>ENVOYER</button>
            </form>
<div>{result ? <Result/> : null }</div>
    
          </div>
      
      </section>
    </>
  )
}

export default DevisE