import React from "react"
import { Link } from "react-router-dom"
import { blog } from "../../data/Data"

import "./footer.css"

const Footer = () => {
    
  return (
    <>
      <section className='newletter'>
        <div className='container flexSB'>
        <div class="rowj">
      <div class="cardh green">
        <h2>Par email</h2>
        <p>avec notre formulaire de contact</p>
        <Link to="/contact">
        <button className='btn-o'>
       NOUS CONTACTER<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
        <img class="image" src="./images/em.png" alt="money" />
      </div>
 
      <div class="cardh blue">
        <h2>Par téléphone</h2>
        <p>
Composer le</p>
        <img class="image" src="./images/telephone.png" alt="settings" />
        <button className='btn-o1'>
        09 88 77 04 88<i className='fa fa-long-arrow-alt-right'></i>
              </button>
      </div>
 
      
    </div>

        </div>
      </section>
      <footer>
        <div className='container padding'>
          <div className='box logo' style={{width: "300px"}} >
          <img src="./images/Logo.png" alt="azur conseil" />
            <h2>PRÉSENTATION</h2>
            <p>Experts du courtage en assurance et mutuelle santé, spécialisés en solutions pour profil à risque aggravé, nous accompagnons particuliers et professionnels partout en France.</p>

            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>
          </div>
          <div className='box link' style={{width: "300px"}}>
            <h3>Navigation</h3>
            <ul>
              <li><Link className="linkA" to='/about'>Cabinet Azur Conseil|Comparateur d'assurance en ligne</Link></li>
              <li><Link className="linkA" to='/assurance'>Assurance</Link></li>
              <li><Link className="linkA" to='/mutuelle'>Mutuelle santé</Link></li>
            
              <li><Link className="linkA" to='/journal'>Actualités</Link></li>
            </ul>
          </div>
          <div className='box link' style={{width: "200px"}}>
            <h3></h3>
            <ul>
              <li><Link className="linkA" to='/contact'>Contact</Link> </li>
              <li><Link className="linkA" to='/devis'>Devis en ligne</Link></li>
              <li><Link className="linkA" to='/etreAppeler'>Etre rappelé</Link></li>
              <li><Link className="linkA" to='/planSite'>Plan du site</Link></li>
           
            </ul>
          </div>
     
          <div className='box last'>
            <h3>Vous avez une question ?</h3>
            <p>
                <i className='fa fa-map'></i>
                60 avenue de Nice
06800
Cagnes-sur-Mer
FRANCE</p>
<br/>
            <p>
                <i className='fa fa-phone-alt'></i>
                09 88 77 04 88
                </p><br/>
                <p>
                <i className='fa fa-paper-plane'></i>
                contact@azur-conseil.fr
                </p>
          </div>
        </div>
      </footer>
      <div className='legal'>
        <p>  <Link  style={{color:"#3c5da8" , fontSize:"13px"}} to="/about">© Azur Conseil 2023 </Link> |      <Link  style={{color:"#3c5da8" , fontSize:"13px"}} to="/Legal">Mentions légales</Link> |     <Link  style={{color:"#3c5da8" , fontSize:"13px"}} to="/planSite">Plan du site </Link> |  <Link  style={{color:"#3c5da8" , fontSize:"13px"}} to="/about"> Gestion des cookies</Link>

 </p>
        <p>
          Copyright ©2023 All rights reserved | This template is made by GEDPLUS
        </p>
      </div>
    </>
  )
}

export default Footer