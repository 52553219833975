import React, { useRef, useState } from "react"
import Back from "../back/Back"
import emailjs from 'emailjs-com';
import Select from 'react-select'
import "./devis.css"
import RadioButtonGroup from "./RadioButtonGroup";
const Result = () =>{
    return(
        <p>successfully</p>
    )
}


const Devis = () => {
    const [result ,showResult] = useState(false)
    const [SelectValue, setSelectValue] = useState("Faites votre choix");
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [ville, setVille] = useState("");
    const [message, setMessage] = useState("");
    const [codepostal, setCodepostal] = useState("");

    const [adresse, setAdresse] = useState("");

      const [date, setDate] = useState('');
      const [dateEffet,     setDateEffet] = useState('');
      const dateInputRef = useRef(null);
     const dateEffetInputRef = useRef(null);
      const handleChange2 = (e) => {
        setDate(e.target.value);
    
      };
      const handleChange3 = (e) => {
          setDateEffet(e.target.value);
      
        };
   
 const emailContent ={
    FName: FName,
    LName: LName,
    phone: phone,
    email: email,
    ville: ville,
    codepostal: codepostal,
    adresse: adresse,
    SelectValue:SelectValue,
date:date,
dateEffet :dateEffet ,

    message: message,

 };
    const sendEmail = (e) => {
        e.preventDefault();
      console.log( emailContent)
        emailjs.send('service_lgygw4b','template_8fdkvz5',emailContent,'vXsPUqEyTvSoTsdj_').then((result)=> {
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
    }
    setTimeout(()=>{
        showResult(false);
    }, 5000)
    const handleChange = event => {
      console.log(event.target.value);
      setSelectValue(event.target.value);
    };
  return (
    <>
      <Back title="DEMANDE DE DEVIS " />
      <section className='contacts padding'>
        <div className='container shadow' >
       
        <h1>Demandez un devis gratuit en quelques clics.</h1>
        <br/>
            <form onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Nom *'className="input" name="FName"  onChange={(event) => {setFName(event.target.value)}} required/>
                <input type='text' placeholder='Prénom *' className="input" name="LName"  onChange={(event) => {setLName(event.target.value)}} required />
              </div>
              <input type='text' placeholder='Téléphone *' className="input" name="phone" onChange={(event) => {setPhone(event.target.value)}}  required />
              <input type='email'placeholder='Email *' className="input" name="email" onChange={(event) => {setEmail(event.target.value)}}  required  />
              <input type='text'placeholder='Adresse *' className="input" name="adresse"  onChange={(event) => {setAdresse(event.target.value)}} required  />
              <div className='flexSB'>
                <input type='text' placeholder='Code postal' className="input" name="codepostal" onChange={(event) => {setCodepostal(event.target.value)}} required />
                <input type='text' placeholder='Ville'className="input" name="ville" onChange={(event) => {setVille(event.target.value)}} required />
              </div>
              
              <div className='flexSB'>
<p>Votre demande concerne ? * :</p>
      <select name='SelectValue' onChange={handleChange} style={{width:"650px"}}   >
  <option value='Faites votre choix'>Faites votre choix</option>
 
  <option value="Assurance auto conducteur résilié">Assurance auto conducteur résilié</option>

  <option value="Assurance habitation">Assurance habitation</option>
  <option value="Assurance emprunteur">Assurance emprunteur</option>
 
  <option value="Mutuelle santé senior">Mutuelle santé senior</option>
  <option value="Mutuelle TNS indépendant">Mutuelle TNS indépendant</option>

  
</select></div>


 
    
<div className='flexSB'>
<p>Date de naissance * :</p>

 <input
        type="date"
className="input"
style={{width:"650px"}} 
        onChange={handleChange2}
        ref={dateInputRef}
      /></div>
  
  <div className='flexSB'>
<p>Date d'effet * :</p>
 <input
        type="date"
className="input"
style={{width:"650px"}} 
        onChange={handleChange3}
        ref={dateEffetInputRef}
      /></div>
{console.log("dddddddd",date)}
     

  <br />
              <textarea type='text' placeholder="D'autres précisions à apporter ? :" name="message"  onChange={(event) => {setMessage(event.target.value)}}  required />
            
              <button className='primary-btn'>ENVOYER</button>
            </form>
<div>{result ? <Result/> : null }</div>
            
          </div>
      
      </section>
    </>
  )
}

export default Devis