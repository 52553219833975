import React from 'react'
import Back1 from '../back/Back1'
import MutuelleCard from './MutuelleCard'
import MutuelleTitle from './MutuelleTitle'



const Mutuelle =()=> {
    return(<>

    <Back1 title='Mutuelle santé' />
    <MutuelleTitle/>
    <MutuelleCard/>
  

    </>)
}
export default Mutuelle