import React from "react"
import { Link } from "react-router-dom"
import Devis from "../resilie/Devis"




const MotoCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/moto.jpg' alt='assurance moto / scooter ' />
          </div>
          <div className='right1 row'>
      <h2>
Trouver un contrat d’assurance moto / scooter risque aggravé pas cher</h2>
         
            <p >  <br/> <p >Comparer les assurances pour vous offrir le meilleur des accompagnements est notre métier.
      <br/>      Après un entretien approfondi, qui nous permet de mieux vous connaître et de délimiter vos besoins, vos exigences (cylindrée, marque de votre deux-roues, types de garantie, options, etc.) et vos contraintes, nous nous mettons à la recherche du<a style={{color:"#3baa36"}}> contrat d’assurance moto / scooter risque aggravé le plus à même de vous protéger.</a> 
      <br/>Ce que vous propose Azur Conseil ? <Link to="/assurance">Un contrat d’assurance moto / scooter pas cher</Link>, certes, mais pas seulement. Nous vous offrons du temps, puisque nous nous chargeons de tout, de la recherche aux démarches administratives, en passant par la résiliation de votre précédent contrat (comme pour <a style={{color:"#3baa36"}}>tous nos produits d’assurance </a>).
</p></p>

           

                                 </div>
          </div>
          <div className='container'>
       
      
          <h2>Quelle formule d’assurance moto / scooter choisir quand on est à risque aggravé ?</h2>

<p ><br/>
Le choix de la formule d’assurance moto / scooter est essentiel : elle doit en effet vous protéger efficacement en cas d’accident ou de sinistre.<br/>
<p ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au tiers + responsabilité civile obligatoire : </span> formule minimale</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Au tiers étendu : </span>  assurance intermédiaire, elle comprend la responsabilité civile, couvre le bris de glace, l’incendie et le vol</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Tous risques :  </span> formule la plus protectrice</p>
                      Vous voulez en savoir davantage sur les garanties comprises dans chaque contrat ? Les options possibles (assistance dépannage, prêt de moto ou scooter, etc.) ? Elles varient selon les assureurs et les contrats : c’est pourquoi <span style={{fontWeight: "500", }}> Azur Conseil se charge de ne sélectionner que les meilleures formules, adaptées à votre profil à risque aggravé.</span>
 </p>
            

  </div>
  <div className='container'>
       <br/>
      
       <h2>
Demandez votre devis assurance moto / scooter</h2>
<Devis/>
</div>
                               <div className='container '>
                               <hr class="solidDivider" style={{marginTop: "10px"}}/>
          <div className='title' style={{marginTop:"0px"}}>
       
  
         <p style={{padding: "20px"}}>
         N’hésitez pas à nous contacter pour une étude de votre dossier. Nous vous soumettrons rapidement une proposition de contrat par cher après un entretien approfondi.
         </p>
  
         <Link to="/contact">  
                     
    
         <button className='btn-orange'>
        CONTACTER VOTRE SPÉCIALISTE CONTRAT ASSURANCE MOTO / SCOOTER RISQUE AGGRAVÉ <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
        
      </section>

    </>
  )
}

export default MotoCard