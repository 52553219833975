import React from 'react'
import Back from '../back/Back'
import ResilieCard from './ResilieCard'
import ResilieTitle from './ResilieTitle'





const Resilie =()=> {
    return(<>

    <Back title='ASSURANCE AUTO CONDUCTEUR RÉSILIÉ' />
    <ResilieTitle/>
    <ResilieCard/>

 

    </>)
}
export default Resilie