
import React, { useState } from "react"
import Back from "../back/Back"





const Partenaires = () => {
  
  return (
    <>
      <Back title='Partenaires' />
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title' style={{marginLeft: "80px"}}>
            <h2>Nos partenaires en assurance, mutuelle santé et prévoyance</h2>
            <hr class="solid"/>
            <br/>
           
   
          </div>

        </div>
        <div className='container flexSB'>
            <div>
        <p style={{padding: "20px" , paddingLeft:"140px", fontSize:"25px"}}>
        <p>
        <a style={{color:"#3c5da8" , fontSize:"22px"}} > <i className='fa fa-long-arrow-alt-right'></i> SPVIE</a></p>
      
     
     
     
       <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> FMA </a></p>
      
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> APRIL </a></p>
        
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> NETVOX ASSURANCES </a></p>
        
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> Alptis </a></p>
        
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> MILLICOURTAGE </a></p>
        
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> WAZARI </a></p>
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> MUTUELLE DES MOTARDS </a></p>
        <p>  <a style={{color:"#3c5da8" , fontSize:"22px"}}> <i className='fa fa-long-arrow-alt-right'></i> ECA</a></p>
        </p>    </div><div>
        <p style={{padding: "20px" , paddingLeft:"140px", fontSize:"25px"}}>
      
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> COVERITY </a></p>
     
        <p> <a style={{color:"#3c5da8"  , fontSize:"22px"}} > <i className='fa fa-long-arrow-alt-right'></i> ZEPHIR</a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> ASSUREMA </a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> SOLLY AZAR </a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> EURODOMMAGES </a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> SIMULASSUR by Eloïse </a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> KIASSURE </a></p>
        <p> <a style={{color:"#3c5da8" , fontSize:"22px"}}  > <i className='fa fa-long-arrow-alt-right'></i> MAXANCE </a></p>
 </p>
 

     </div>    </div>
      </section>
    </>
  )
}

export default Partenaires