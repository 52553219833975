import React from "react"


const IndependentTitle = () => {
  return (
  <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Mutuelle TNS et travailleur indépendant : des garanties dédiées</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Si un travailleur salarié peut disposer d’une mutuelle via son employeur, ce n’est pas le cas du travailleur non salarié (TNS), également appelé travailleur indépendant ou freelance.</h4>
         <p style={{padding: "20px"}}>
        
         Il existe de nombreuses mutuelles santé et diverses garanties dédiées à ce statut. Azur Conseil vous aide à y voir clair et à choisir <a style={{color:"#3baa36"}}>  celle qui collera le mieux à vos attentes et à votre budget. </a>

 
 

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default IndependentTitle