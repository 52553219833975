import React from "react"
import { Link } from "react-router-dom"
import "./services.css"

const Service =()=>{
    const ServicesData = [
        {
          id: 1,
          title: "Assurence auto conducteur sans antécédents",
          cover: "../images/services/1.jpg",
          link: "/AssuranceAutoConducteurSansAntécédents"

        },
        {
          id: 2,
          title: "Assurance auto conducteur résilié",
         
          cover: "../images/services/2.jpg",
          link: "/AssuranceAutoConducteurRésilié"
        },
        {
          id: 3,
          title: "Assurence moto scooter",
   
          cover: "../images/services/3.jpg",
          link: "/AssuranceMotoScooter"
        },
        {
          id: 4,
          title: "Assurance haitation",
      
          cover: "../images/services/4.jpg",
          link: "/AssuranceHabitation"
        },
        {
          id: 5,
          title: "Assurance emprunteur",
       
          cover: "../images/services/5.jpg",
          link: "/AssuranceEmprunteur"
        },
        {
          id: 6,
          title: "Autre produits d'assurance",
    
          cover: "../images/services/6.jpg",
          link: "/AutreProduitsAssurance"
        },
      ]
      
    return(<>
    <section className="services topMarign">
        <div className="container">
         
            <div className="contain grid2 topMargin">
                {
                    ServicesData.map((val)=>{
                        return(
                            <>
                            <div className="box">
                                <div className="img">
                                    <img src={val.cover} alt ="services assurances" style={{width: "350px"}}/></div>
                                    <div className="text">
                                        <h3>{val.title}</h3>
                                        <Link to={val.link}>
                                        <button className='btn-v'>
        EN SAVOIR PLUS<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
                                      </div></div></>
                        )
                    })
                }
            </div>
        </div>
    </section>
    </>)
}
export default Service;