import React from "react"
import { Link } from "react-router-dom"




const MutuelleSeniorCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/senior.jpg' alt='Mutuelle senior' />
          </div>
          <div className='right1 row'>
      <h2>
      Comment choisir une mutuelle senior ?</h2>
         
            <p >  <br/> <p >Le choix est vaste parmi les nombreuses offres de mutuelle senior. Or, vous êtes unique. Il vous faut donc choisir une complémentaire santé et des garanties qui collent à vos besoins et vos contraintes budgétaires.
      <br/>    <a style={{color:"#3baa36"}}> Les points à prendre en compte ? </a>Votre régime social (régime général, agricole, spéciaux, travailleur non salarié), vos antécédents de santé, le nombre d’ayant droit, etc. N’oubliez pas que vous pouvez être senior et actif !
      <div className="box">
                            
                            <div className="text">
            
                                <p>Les garanties et le prix des cotisations peuvent varier d’un assureur à l’autre : frais d’hospitalisation, soins dentaires, optiques, auditifs, consultations médicales, etc. Il est important de les comparer !
                         </p></div></div>
</p></p>

           

                                 </div>
          </div>
          <div className='container'>
       <br/>
      
          <h2>Pourquoi faire appel à un courtier pour sa mutuelle senior ?</h2>

<p ><br/>
Parce qu’il est difficile de s’y retrouver parmi les offres, les niveaux de remboursement et les garanties complémentaires. Et que vous n’avez peut-être pas de temps à consacrer à une analyse approfondie…<br/>
<br/><p > <Link to="/about"> Azur Conseil, en tant que courtier en mutuelle senior </Link>implantée dans les Alpes Maritimes (06), connaît bien le marché, vous offre un œil expert et <a style={{color:"#3baa36"}}>  ne retient pour vous que les solutions qui vous correspondent, selon votre budget.</a> Un entretien approfondi, par téléphone, nous permet de cibler vos attentes, vos besoins et ainsi de comparer les offres de manière efficace.</p>
                 <br/>     <p  style={{  color:"#3baa36", fontWeight:"500"}}>Vous vous interrogez sur l’intérêt de souscrire une mutuelle senior depuis la réforme « 100 % Santé » ? Ce concept a pour but de faciliter l’accès aux soins à tous les Français, quels que soient leurs revenus.</p>
                 <br/>          <p  >Concrètement, les Français voient l’intégralité des soins optiques, auditifs et dentaires remboursés par l’assurance maladie lorsqu’ils choisissent dans un panel d’équipements médicaux spécifiques (dont le prix est plafonné). Pour un reste à charge zéro.</p>
                  
 </p>
 <div className="box">
                            
                            <div className="text">
                  
                                <p> Bien sûr, si vous souhaitez un équipement différent, qui sort du cadre de la prise en charge de la réforme 100 % santé, une mutuelle senior de qualité assurera le remboursement du reste à charge de vos dépenses de santé.
                         </p></div></div>
            

  </div>
                               <div className='container '>
  
          <div className='title' style={{marginTop:"0px"}}>
          <hr class="solidDivider"/>

         <p style={{padding: "20px"}}>
         Vous avez envie d’en savoir plus sur <Link to="/mutuelle"> les prestations de mutuelle santé</Link> ? Les garanties proposées en cas de reste à charge ?<br/>

N’hésitez pas à contacter notre équipe d’experts ! Basés en France, à Cagne sur Mer (06), ils vous répondent dans les plus brefs délais et vous aident à comparer !
         </p>
  
                     
         <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE COURTIER EXPERT EN MUTUELLE SENIOR<i className='fa fa-long-arrow-alt-right'></i>
              </button>
              </Link>
          </div>
    
          </div>
        
      </section>

    </>
  )
}

export default MutuelleSeniorCard