import React from "react"
import { useLocation } from "react-router-dom"
import "./conducteurMalussé.css"

const BackM = ({ title }) => {
  const location = useLocation()

  return (
    <>
      <section className='back'>
        <h2>Accueil / {title}</h2>
        <h1>{title}</h1> 
      </section>
      <div className='margin'></div>
    </>
  )
}

export default BackM