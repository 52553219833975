import React, { useState } from 'react'
import Head from './Head'

import { Link } from "react-router-dom"
import './header.css'

const Header = () => {
    const [click, setClick] = useState(false)
  return (
    <>
<Head/>
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='/'>Accueil</Link>
            </li>
            <li>
              <Link to='/about'>Cabinet Azur Conseil</Link>
              <div className='sub-menu-1'>
                <ul>
              <li>
              <Link to='/ConducteurMalussé'>Conducteur malussé : comment assurer sa voiture ?</Link>
            </li>
            <li>
              <Link to='/Freelance'>Freelance et complémentaire santé</Link>
            </li>
            </ul>
              </div>
     
            </li>
            <li className='vert'>
              <Link  to='/assurance'>Assurance</Link>
              <div className='sub-menu-1' >
                <ul>
              <li>
              <Link className='vertb' to='/AssuranceAutoConducteurSansAntécédents'>Assurance auto conducteur sans antécédents</Link>
            </li>
            <li>
              <Link  className='vertb' to='/AssuranceAutoConducteurRésilié'>Assurance auto conducteur résilié</Link>
            </li>
        
            <li>
              <Link className='vertb' to='/AssuranceHabitation'>Assurance habitation</Link>
            </li>
            <li>
              <Link className='vertb' to='/AssuranceEmprunteur'>Assurance emprunteur</Link>
            </li>
            <li>
              <Link className='vertb' to='/AutreProduitsAssurance'>Autre produits d'assurance</Link>
            </li>
            </ul>
              </div>
            </li>
            <li className='orange'>
              <Link to='/mutuelle'>Mutuelle santé</Link>
              <div className='sub-menu-1'>
                <ul>
              <li>
              <Link className='orangeb' to='/Mutuellesenior'>Mutuelle senior</Link>
            </li>
            <li>
              <Link className='orangeb' to='/MutuelleTNSIndépendant'>Mutuelle TNS indépendant</Link>
            </li>
            </ul>
              </div>
            </li>
        
            <li>
              <Link to='/journal'>Actualités</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
          <div className='start'>
            <div className='button' >
            <Link to='/devis' >DEVS EN LIGNE</Link>   </div>
          </div>
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      </header>
    </>
  )
}

export default Header
