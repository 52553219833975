import React from "react"
import "./Accueil.css"

import Title from "../common/title/Title"
import { Link } from "react-router-dom"

const Service = () => {
    const online = [
        {cover: "/images/services/6.2.png",
        hoverCover: "/images/services/6.1.png",
      
          courseName: "Assurance Auto",
          link: "/AssuranceAutoConducteurSansAntécédents",
        },
        {   cover: "./images/services/g1.png",
        hoverCover: "/images/services/g2.png",
    
          courseName: "Autres produits d’assurance ",
          link: "/AutreProduitsAssurance",
        },
        {          cover: "/images/services/4.2.png",
        hoverCover: "/images/services/4.1.png",
         
          courseName: "Assurance Habitation",
          link: "/AssuranceHabitation",
        },
        {
            cover: "/images/services/3.2.png",
            hoverCover: "/images/services/3.1.png",
          courseName: "Mutuelle Santé",
          link: "/mutuelle",
        },
        {
            cover: "/images/services/2.2.png",
            hoverCover: "/images/services/2.1.png",
          courseName: "Assurance Emprunteur",
          course: "/AssuranceEmprunteur",
        },
        {    cover: "./images/services/g3.png",
        hoverCover: "/images/services/g4.png",
          
          courseName: "Mutuelle TNS indépendant",
          link: "/MutuelleTNSIndépendant",
        },
     
        
      ]
  return (
    <>
      <section className='online '>
        <div >
    
          <div className='content grid2'>
            {online.map((val) => (
              <div className='box'>
                <div className='img'>
                  <img src={val.cover} alt='service' />
                  <img src={val.hoverCover} alt='service' className='show' />
                </div>
                <h1>{val.courseName}</h1>
                <Link to={val.link}>
                <button className='btn-b'>
        EN SAVOIR PLUS<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Service