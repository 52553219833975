import React from "react"
import { Link } from "react-router-dom"
import Header from "../../common/heading/Header"
import Title from "../../common/title/Title"
import { useHistory } from "react-router-dom";
import "./hero.css"

const Hero = () => {
  const history = useHistory();
  
  function handleClick() {
    history.push("/devis");
    console.log("saharrrrrrrrr")
  }
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Title subtitle='PARTICULIERS & PROFESSIONNELS' title='COURTIER EN ASSURANCE & MUTUELLE SANTE' />
            <p>Jeunes, conducteurs résiliés, personnes à risque aggravé, seniors, professionnels...</p>
       
          </div>
        </div>
      </section>
      <div className='margin'>     <div className='button'>
  
  <button className='primary-btn' type="button" onClick={handleClick}>
MON DEVIS PERSONNALISÉ <i className='fa fa-long-arrow-alt-right'></i>
  </button>
  <Link to="/etreAppeler">
  <button  >
  ÉTRE APPELÉ <i className='fa fa-long-arrow-alt-right'></i>
  </button></Link>
  <Link to="/contact">
  <button className='primary-btn'>
 CONTACTEZ NOUS <i className='fa fa-long-arrow-alt-right'></i>
  </button></Link>
  
</div></div>
    </>
  )
}

export default Hero