import React from 'react'
import Back from '../back/Back'
import Back1 from '../back/Back1'
import IndependantCard from './IndependantCard'
import IndependentTitle from './IndependantTitle'








const Independant =()=> {
    return(<>

    <Back1 title='MUTUELLE TNS INDÉPENDANT' />
    <IndependentTitle />
    <IndependantCard/>



 

    </>)
}
export default Independant