
import './App.css';
import Header from './common/heading/Header';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from './home/Home';
import About from './about/About';
import Assurance from './assurance/Assurance';
import Mutuelle from './mutuelle/Mutuelle';
import Professionnelle from './professionnelle/Professionnelle';
import ConducteurMalussé from './conducteurMalussé/ConducteurMalussé';
import Freelance from './freelance/Freelance';
import SansAntecedents from './sansAntecedents/SansAntecedents';
import Resilie from './resilie/Resilie';
import Moto from './moto/Moto';
import Habitation from './Habitation/Habitation';
import Emprunteur from './emprunteur/Emprunteur';
import AutreProduit from './autreProduit/AutreProduit';
import MutuelleSenior from './mutuelleSenior/MutuelleSenior';
import Independant from './Independant/Independant';
import Contact from './contact/Contact';
import Devis from './devis/devis';
import EtreAppler from './etreAppeler/EtreAppeler';
import Footer from './common/footer/Footer';
import Blog from './blog/Blog';
import BlogDetail from './blogDetail/BlogDetail';
import Plan from './plan/PLan';
import Partenaires from './Partenaires/Partenaires';
import Legal from './legal/Legal';


function App() {
  return (
    <>
 
     <Router>
     <Header/>
      <Switch>
      <Route path='/' exact component={Home} />
      <Route path='/about' exact component={About} />
      <Route path='/assurance' exact component={Assurance} />
      <Route path='/mutuelle' exact component={Mutuelle} />
      <Route path='/professionnelle' exact component={Professionnelle} />

      <Route path='/ConducteurMalussé' exact component={ConducteurMalussé} />
      <Route path='/Freelance' exact component={Freelance} />
      <Route path='/AssuranceAutoConducteurSansAntécédents' exact component={SansAntecedents} />
      <Route path='/AssuranceAutoConducteurRésilié' exact component={Resilie} />
      <Route path='/AssuranceMotoScooter' exact component={Moto} />
      <Route path='/AssuranceHabitation' exact component={Habitation} />
      <Route path='/AssuranceEmprunteur' exact component={Emprunteur} />
      <Route path='/AutreProduitsAssurance' exact component={AutreProduit} />
      <Route path='/Mutuellesenior' exact component={MutuelleSenior} />
      <Route path='/MutuelleTNSIndépendant' exact component={Independant} />
      <Route path='/contact' exact component={Contact} />
      <Route path='/devis' exact component={Devis} />
      <Route path='/etreAppeler' exact component={EtreAppler} />
      <Route path='/journal' exact component={ Blog} />
      <Route path="/blog/:id"  exact component={BlogDetail} />
      <Route path="/planSite" exact component={Plan} />
      <Route path="/Legal" exact component={Legal} />
      <Route path="/Partenaires" exact component={Partenaires} />

      </Switch>
   <Footer/>
      </Router>
    </>
  );
}

export default App;