import React from "react"
import { Link } from "react-router-dom"


import Title from "../common/title/Title"

import './Accueil.css'
import Service from "./Service"
const AccueilTitle = () => {
  return (
    <>
      <section className='accueil' >
        <div className='container ' >
   
        <div className='title'>
          <h4 style={{ marginBottom:"-50px",fontSize:"20px"}}>DÉCOUVRIR</h4>
            <h2 >Offre d'assurance ou mutuelle pour tout profil</h2>
            <br/>
            <hr class="solid"/>
           
         <p className="p">
        
         Que vous soyez jeune, résilié, senior, professionnel ou à risque aggravé, vous êtes assuré d’obtenir le meilleur prix.

 
 

         </p>
         <Link to="/AutreProduitsAssurance">
         <button className='btn-orange' style={{padding: "20px", marginBottom: "20px"}}>
        NOS AUTRES ASSURANCES & MUTUELLES<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
          <Service/>
      
        </div>
      </section>
  
    </>
  )
}

export default AccueilTitle