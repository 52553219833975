
import React, { useState } from "react"
import Back from "../back/Back"





const Legal = () => {
  
  return (
    <>
      <Back title='MENTIONS LÉGALES' />
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title' style={{marginLeft: "400px"}}>
            <h2>MENTIONS LÉGALES</h2>
            <hr class="solid"/>
            <br/>
           
   
          </div>

        </div>
        <div className='container flexSB'>
            <div>
        <p style={{padding: "20px" , paddingLeft:"100px", fontSize:"20px"}}>
        <a style={{color:"#3c5da8" , fontSize:"20px"}} >
        Édition/publication du site : </a>
Azur Conseil<br/>
60 avenue de Nice, 06800 Cagnes-sur-Mer<br/>
<a style={{color:"#3c5da8" , fontSize:"20px"}} >
Tél. :</a> 09 88 77 04 88<br/>

<a style={{color:"#3c5da8" , fontSize:"20px"}} >
Dénomination sociale : </a >AZUR CONSEIL<br/>
<a style={{color:"#3c5da8" , fontSize:"20px"}} >Capital social : </a>4000 €<br/>
<a style={{color:"#3c5da8" , fontSize:"20px"}} >N° Siret :</a> 88455719000011<br/>
<a style={{color:"#3c5da8" , fontSize:"20px"}} >RCS :</a> RCS Antibes 884557190<br/>
<a style={{color:"#3c5da8" , fontSize:"20px"}} >N° TVA intracommunautaire :</a> FR 96 884557190<br/>

<a style={{color:"#3c5da8" , fontSize:"20px"}} >N° ORIAS :</a> 20005443<br/>

<a style={{color:"#3c5da8" , fontSize:"20px"}} >Directeur de la publication : </a>

Mme Ines RAZOUENE<br/>
<p style={{color:"#3c5da8" , fontSize:"20px"}} >
Respect de la vie privée et des données personnelles :</p>
Les données sont collectées dans le but exclusif d'établir une relation commerciale et professionnelle avec les clients.
Les données à caractère personnel sont conservées par Azur Conseil pendant une période raisonnable.
Si la personne ne fournit pas toutes les informations obligatoires dans les formulaires du site (*Champs obligatoires), elle ne peut valider le formulaire.
Le droit d'accès et de rectification prévu à l'article 34 et suivants de la loi précitée s'exerce par courrier à l'adresse :
Azur Conseil - 60 avenue de Nice, 06800 Cagnes-sur-Mer
<p style={{color:"#3c5da8" , fontSize:"20px"}} >
Droits de reproduction :</p>
L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris les représentations iconographiques et photographiques. La reproduction de tout ou partie de ce site sur un support quel qu'il soit est formellement interdite, sauf autorisation expresse du directeur de la publication.

<p style={{color:"#3c5da8" , fontSize:"20px"}} >

Photographies : </p>

Les photographies utilisées dans ce site font l'objet de droits réservés. Elles ne peuvent pas être utilisées ou dupliquées sans l'autorisation écrite de Azur Conseil.
<p style={{color:"#3c5da8" , fontSize:"20px"}} >
Crédits photographiques : </p> Adobe Stock / Istock / Flaticon / freepik
<p style={{color:"#3c5da8" , fontSize:"20px"}} >
Avis relatif à la sécurité :</p> 
Afin d'assurer sa sécurité et de garantir son accès à tous, ce site internet emploie des logiciels pour contrôler les flux sur le site, pour identifier les tentatives non autorisées de connexion ou de changement de l'information, ou toute autre initiative pouvant causer d'autres dommages. Les tentatives non autorisées de chargement d'information, d'altération des informations, visant à causer un dommage et d'une manière générale toute atteinte à la disponibilité et l'intégrité de ce site sont strictement interdites et seront sanctionnées par le code pénal. Ainsi l'article 323-1 du code pénal prévoit que le fait d'accéder ou de se maintenir frauduleusement, dans tout ou partie d'un système de traitement automatisé de données (c'est le cas d'un site internet) est puni de deux ans d'emprisonnement et de 30 000 euros d'amende. L'article 323-3 du même code prévoit que le fait d'introduire frauduleusement des données dans un système de traitement automatisé ou de supprimer ou de modifier frauduleusement les données qu'il contient est puni de cinq ans d'emprisonnement et de 75 000 euros d'amende.

 
<p style={{color:"#3c5da8" , fontSize:"20px"}} >
Les fonctionnalités de Google Analytics pour les annonceurs sont activées pour sur ce site (Remarketing). </p>
Google utilise des cookies pour diffuser nos annonces sur le réseau de recherche Google, les partenaires du Réseau de recherche ainsi que sur les sites de son réseau Display.
Grâce au cookie DoubleClick, Google adapte les annonces diffusées aux utilisateurs en fonction de leur navigation sur notre site et prenant en compte la navigation multi appareils.
Vous pouvez désactiver l'utilisation de cette fonctionnalité en vous rendant sur le gestionnaire de préférences pour les annonces.
        </p>    
 

     </div>    </div>
      </section>
    </>
  )
}

export default Legal