import React from "react"
import { Link } from "react-router-dom"
import DevisH from "./DevisH"





const HabitationCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/habitation.jpg' alt='Assurance habitation' />
          </div>
          <div className='right1 row'>
      <h2>Assurance habitation et radiation : faire appel à un courtier spécialisé en profil à risque aggravé</h2>
         
            <p> <br/> <p >Vous vous demandez s’il ne serait pas plus simple de ne pas reprendre d’assurance habitation pour votre maison, votre appartement, votre studio après votre radiation ? Ce serait une grave erreur !
<br/>
<a style={{color:"#3baa36"}}>
Une assurance habitation assure en effet la protection de vos biens mais également des personnes qui y vivent.</a> Que vous soyez locataire ou propriétaire, l’assurance habitation est obligatoire.

</p></p>
<div className="box">
                            
                            <div className="text">
            
                                <p>Il n’est pas simple de retrouver un assureur lorsqu’on a été radié. C’est pourquoi faire appel à un courtier spécialisé en profil à risque aggravé vous soulagera des démarches complexes et du stress inhérent à ce genre de situation… 
                         </p></div></div>
            </div>
          </div>
          <div className='container'>
        
       

  

<p >Azur Conseil,<Link to="/assurance"> courtier en assurance</Link> , vous propose un service complet : nous nous chargeons de tout de A à Z, après un entretien approfondi qui nous permet de connaître vos contraintes et vos attentes. <a style={{color:"#3baa36"}}>Pas d’inquiétude, même si votre profil est à risque aggravé, il existe des solutions pour que vous soyez couvert au minimum sur les garanties obligatoires !</a>
 </p>

                               </div>
                              
                               <div className='container'>
       <br/>
      
       <h2>Demandez votre devis assurance habitation</h2>
<DevisH/>
</div>




                               <div className='container '>
                               <hr class="solidDivider" style={{marginTop: "10px"}}/>    
          <div className='title' style={{marginTop:"0px"}}>

                 <br/>
            <p>N’hésitez pas à contacter Azur Conseil pour obtenir un devis et des propositions de contrats d’assurance habitation. Nos conseillers, basés en France (à Cagne sur Mer, dans les Alpes Maritimes), sont à votre écoute par téléphone ou via notre site.
            <br/><br/>    </p>
            <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE EXPERT EN ASSURANCE HABITATION POUR PROFIL A RISQUE AGGRAVÉ<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
    
      </section>

    </>
  )
}

export default HabitationCard