import React from "react"


const HabitationTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Trouver une assurance habitation quand on a été radié</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous avez cumulé les sinistres dans votre maison ? Vous n’avez pas payé vos cotisations dans les 30 jours suivant le rappel de votre assureur ? Vous avez fait une fausse déclaration ?</h4>
         <p style={{padding: "20px"}}>
        
         Quelle qu’en soit la raison, votre compagnie d’assurance vous considère comme personne à risque aggravé et a décidé de résilier votre contrat :  <a style={{color:"#3baa36"}}>vous allez bientôt être radié et vous retrouver sans assurance habitation.
       </a>
       <br/>
       <a style={{color:"#ee8a00"}}>
       La seule solution pour trouver un nouvel assureur ? Passer par un courtier spécialisé en profil à risque aggravé.</a>
       <br/>Azur Conseil se charge de vous trouver une nouvelle assurance habitation qui réponde à vos besoins dans l’immédiat.

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default HabitationTitle