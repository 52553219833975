import React from "react"


const SansAntecedentsTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Assurance auto pour conducteur sans antécédents ou non assuré depuis plus de 3 ans</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous avez votre permis depuis plusieurs années mais votre assurance auto n’était jamais à votre nom ? Vous n’êtes plus assuré pour votre voiture depuis plus de trois ans ?</h4>
         <p style={{padding: "20px"}}>
        
         <a style={{color:"#3baa36"}}>Il est temps de remédier à cela !</a> Et ce n’est pas une mince affaire, car les compagnies d’assurance sont souvent réticentes à couvrir les conducteurs sans antécédents ou non assurés depuis plus de trois ans. Et même si elles assurent, c’est à des prix exorbitants… 
       <br/>  Avec Azur Conseil, vous pouvez espérer mieux !      <a style={{color:"#3baa36"}}> Spécialistes en profil à risque aggravé, nous mettons tout en œuvre pour vous trouver un contrat d’assurance auto conducteur sans antécédents qui vous vous offre une couverture adaptée et respectueuse de votre budget.</a>

 

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default SansAntecedentsTitle