import React from 'react'

import Back1 from '../back/Back1'
import MutuelleSeniorCard from './MutuelleSeniorCard'
import MutuelleSeniorTitle from './MutuelleSeniorTitle'







const MutuelleSenior =()=> {
    return(<>

    <Back1 title='MUTUELLE SENIOR' />
    <MutuelleSeniorTitle/>
    <MutuelleSeniorCard/>


 

    </>)
}
export default MutuelleSenior