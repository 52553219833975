import React from "react"







const ProfessionnelleTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Assurance professionnelle : l’importance de la RC Pro et de la garantie décennale</h2>
            <hr class="solid"/>
      
       <br/>
        <h4> Personne n’est à l’abri d’une erreur, d’une maladresse… C’est pourquoi, quel que soit votre secteur d’activité, il est important de protéger vos salariés et votre entreprise de tout dommage pouvant vous être imputé dans le cadre professionnel.</h4>

         <p style={{padding: "20px"}}>
Azur Conseil trouve pour vous les meilleurs contrats d’assurance professionnelle : responsabilité civile professionnelle, garantie décennale, etc. <span style={{fontWeight:"500"
}}>Pour que vous exerciez votre activité en toute votre tranquillité !</span>
         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default ProfessionnelleTitle