import React, { useState } from "react"
import Back from "../back/Back"
import emailjs from 'emailjs-com';
import Select from 'react-select'

const Result = () =>{
    return(
        <p>successfully</p>
    )
}


const EtreAppler = () => {
    const [result ,showResult] = useState(false)
    const [SelectValue, setSelectValue] = useState("Faites votre choix");
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [ville, setVille] = useState("");
    const [message, setMessage] = useState("");
    const [codepostal, setCodepostal] = useState("");
    const [motif, setmotif] = useState("");
    const [adresse, setAdresse] = useState("");
    
 
    const handleChange = event => {
        console.log(event.target.value);
        setSelectValue(event.target.value);
      };
      const handleChange1 = event => {
        console.log(event.target.value);
        setmotif(event.target.value);
      };
      const [selectedOption, setSelectedOption] = useState('Matin');

      function handleChange2(event) {
        setSelectedOption(event.target.value);
        console.log(selectedOption);
      }
      const [checked, setChecked] = React.useState(['Lundi']);

      const [state, setState] = React.useState({
        Lundi: true,
        Mardi: false,
        Mercredi: false,
        Jeudi: false,
        Vendredi: false,
    
      });
      const { Lundi, Mardi,Mercredi ,Jeudi ,Vendredi} = state;
      const handleChange3 = (e) => {
        setState({
          ...state,
          [e.target.name]: e.target.checked,
        });
      };
    
 const emailContent ={
    FName: FName,
    LName: LName,
    phone: phone,
    email: email,
    ville: ville,
    codepostal: codepostal,
    adresse: adresse,
    SelectValue: SelectValue,
    selectedOption: selectedOption,
    state:"Lundi : "+state.Lundi, 
    state1:"Mardi : "+state.Mardi,
    state2:"Mercredi : "+state.Mercredi,
    state3:"Jeudi : "+state.Jeudi,
    state4:"Vendredi : "+state.Vendredi,

 };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log(emailContent)
      
        emailjs.send('service_lgygw4b','template_otksvww',emailContent,'vXsPUqEyTvSoTsdj_').then((result)=> {
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
    }
    setTimeout(()=>{
        showResult(false);
    }, 5000)

  return (
    <>
      <Back title='ETRE RAPPELÉ ' />
      <section className='contacts padding'>
        <div className='container shadow' >
        <h1>Demander à être rappelé par un conseiller Azur Conseil</h1>
        <br/>

            <form onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Nom *' name="FName" className="input"   onChange={(event) => {setFName(event.target.value)}} required/>
                <input type='text' placeholder='Prénom *' className="input"  name="LName"  onChange={(event) => {setLName(event.target.value)}} required />
              </div>
              <input type='text' placeholder='Téléphone *' className="input"  name="phone" onChange={(event) => {setPhone(event.target.value)}}  required />
              <input type='email'placeholder='Email *' className="input"  name="email" onChange={(event) => {setEmail(event.target.value)}}  required  />
              <input type='text'placeholder='Adresse *' className="input"  name="adresse"  onChange={(event) => {setAdresse(event.target.value)}} required  />
              <div className='flexSB'>
                <input type='text' placeholder='Code postal *' className="input"  name="codepostal" onChange={(event) => {setCodepostal(event.target.value)}} required />
                <input type='text' placeholder='Ville *' className="input"  name="ville" onChange={(event) => {setVille(event.target.value)}} required />
              </div>
              <div className='flexSB'>
<p>Votre demande concerne ? * :</p>
      <select name='SelectValue' onChange={handleChange} style={{width:"650px"}}  >
  <option value='Faites votre choix'>Faites votre choix</option>
  <option value="Assurance auto jeune conducteur">Assurance auto jeune conducteur</option>
  <option value="Assurance auto conducteur résilié">Assurance auto conducteur résilié</option>

  <option value="Assurance habitation">Assurance habitation</option>
  <option value="Assurance emprunteur">Assurance emprunteur</option>
  <option value="Autre produits d'assurance">Autre produits d'assurance</option>
  <option value="Mutuelle santé senior">Mutuelle santé senior</option>
  <option value="Mutuelle TNS indépendant">Mutuelle TNS indépendant</option>

  
</select></div>
<div className='flexSBD'>
<p >Quel créneau ? * :</p>
<div style={{width:"500px", marginLeft:"20px" ,marginTop:"5px"}} >
      <label style={{marginRight: "10px"}}  >
      Matin
        <input type="radio" value="Matin" style={{marginLeft: "20px"}}  checked={selectedOption === 'Matin'} onChange={handleChange2} />
        
      </label>
      <br />   <br />
      <label>
      Après-midi
        <input type="radio" value="Après-midi" style={{marginLeft: "20px"}} checked={selectedOption === 'Après-midi'} onChange={handleChange2} />
    
      </label>
     

    </div></div> <br/>
    <div className='flexSBD'>
<p >Quel(s) jour(s) souhaitez-vous être rappelé ? * :</p>
<div style={{width:"500px", marginLeft:"20px" ,marginTop:"5px"}} >
      <label style={{marginRight: "10px"}}  >
      Lundi
        <input type="checkbox" value="Lundi" name="Lundi" style={{marginLeft: "20px"}}  checked={Lundi} onChange={handleChange3} />
        
      </label>
      <br />   <br />
      <label>
      Mardi
        <input type="checkbox" value="Mardi"  name="Mardi" style={{marginLeft: "20px"}} checked={Mardi} onChange={handleChange3} />
    
      </label>
      <br />   <br />
      <label>
      Mercredi
        <input type="checkbox" value="Mercredi"  name="Mercredi" style={{marginLeft: "20px"}} checked={Mercredi} onChange={handleChange3} />
    
      </label>
      <br />   <br />
      <label>
      Jeudi
        <input type="checkbox" value="Jeudi"  name="Jeudi" style={{marginLeft: "20px"}} checked={Jeudi} onChange={handleChange3} />
    
      </label>
      <br />   <br />
      <label>
      Vendredi
        <input type="checkbox" value="Vendredi"  name="Vendredi" style={{marginLeft: "20px"}} checked={Vendredi} onChange={handleChange3} />
    
      </label>
     
     

    </div></div> 
           {console.log(state)} 
            
              <button className='primary-btn'>ENVOYER</button>
            </form>
<div>{result ? <Result/> : null }</div>
         
          </div>
      
      </section>
    </>
  )
}

export default EtreAppler