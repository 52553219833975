
import BlogCard from "../blog/BlogCard"
import Title from "../common/title/Title"

import './Accueil.css'
import BlogA from "./BlogA"
import Service from "./Service"
const BlogTitle = () => {
  return (
    <>
      <section className='accueil1' >
        <div className='container ' >
   
        <div className='title'>
          <h4 style={{ marginBottom:"-50px",fontSize:"20px"}}>AZUR CONSEIL</h4>
            <h2 >Nos actualités</h2>
            <br/>
            <hr class="solid"/>

       
          </div>
       
          <BlogA/>
        </div>
      </section>
  
    </>
  )
}

export default BlogTitle