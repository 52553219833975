import React from "react"
import { Link } from "react-router-dom"

import "./mutuelle.css"
const MutuelleService =()=>{
    const ServicesData = [
        {
          id: 1,
          title: "Mutuelle senior",
          cover: "../images/services/7.jpg",
          link: "/Mutuellesenior"

        },
        {
          id: 2,
          title: "Mutuelle TNS indépendant",
         
          cover: "../images/services/8.jpg",
          link: "/MutuelleTNSIndépendant"
        },
      
      ]
      
    return(<>
    <section className="services topMarign">
        <div className="container">
         
            <div className="contain grid2 topMargin">
                {
                    ServicesData.map((val)=>{
                        return(
                            <>
                            <div className="box">
                                <div className="img">
                                    <img src={val.cover} alt ="services" style={{width: "350px"}}/></div>
                                    <div className="text">
                                        <h3>{val.title}</h3>
                                        <Link to={val.link}>  
                                        <button className='btn-v'>
        EN SAVOIR PLUS<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
                                      </div></div></>
                        )
                    })
                }
            </div>
        </div>
    </section>
    </>)
}
export default MutuelleService;