import { posthog } from "posthog-js";
import { useState } from "react";

function CookieBanner(){
    const[showBanner , setShowBanner] = useState(true);
    const acceptCookies =()=>{
        posthog.opt_in_capturing();
        setShowBanner(false);
    }
    const declineCookies =() =>{
        posthog.opt_out_capturing();
        setShowBanner(false);
    }
return ( <div>
    {
        showBanner && (
            <div className="modal">
            <div className="overlay"></div>
         
            <div className="modal-content">
            <div className='  flexSBf'>
   <div>   <img style={{width:"100px", height:"100px", marginTop:"20px", marginRight:"15px"}} class="image" src="./images/co.png" alt="settings" /></div>
   <div>
              <h2>Gestion des cookies</h2>
              <p>
              Ce site utilise des cookies et vous donne le contrôle sur ceux que vous souhaitez activer
              </p></div></div>
              <button onClick={acceptCookies}>Tout accepter</button>
            <button onClick={declineCookies}>Tout refuser</button>
            </div>
          </div>
       
        )
    }
</div>)
   

}
export default CookieBanner;