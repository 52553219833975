import React from 'react'

import Back2 from '../back/Back2'

import AboutCard from './AboutCard'
import AboutTitle from './AboutTitle'

const About =()=> {
    return(<>

    <Back2 title='Cabinet Azur Conseil' />
    <AboutTitle/>
<AboutCard/>

    </>)
}
export default About