import React, { useState } from "react"
import Back from "../back/Back"
import { Link } from "react-router-dom"





const Plan = () => {
  
  return (
    <>
      <Back title='Plan du site' />
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title' style={{marginLeft: "450px"}}>
            <h2>Plan du site</h2>
            <hr class="solid"/>
            <br/>
           
   
          </div>

        </div>
        <p style={{padding: "20px" , paddingLeft:"140px", fontSize:"25px"}}>
        <p>
        <Link style={{color:"#3c5da8" , fontSize:"22px"}} to="/about" > <i className='fa fa-long-arrow-alt-right'></i> Cabinet Azur Conseil | Comparateur d’assurance en ligne </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/about" > <i className='fa fa-long-arrow-alt-right'></i> Mentions légales </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/planSite" > <i className='fa fa-long-arrow-alt-right'></i> Plan du site </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/etreAppeler" > <i className='fa fa-long-arrow-alt-right'></i>Etre rappelé - Assurance Mutuelle en France </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/about" > <i className='fa fa-long-arrow-alt-right'></i>Partenaires </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/devis" > <i className='fa fa-long-arrow-alt-right'></i>Demande de devis pour un contrat d'assurance </Link></p>
     
     
     
     
       <p> <Link style={{color:"#3c5da8" , fontSize:"22px"}} to="/about" > <i className='fa fa-long-arrow-alt-right'></i> Cabinet Azur Conseil | Comparateur d’assurance en ligne </Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/ConducteurMalussé" > <i className='fa fa-long-arrow-alt-right'></i>Conducteur malussé : comment assurer sa voiture ?</Link></p>
       <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/Freelance" > <i className='fa fa-long-arrow-alt-right'></i>Freelance et complémentaire santé </Link></p>
        <p>  <Link style={{color:"#3c5da8" , fontSize:"22px"}} to="/assurance" > <i className='fa fa-long-arrow-alt-right'></i> Assurance </Link></p>

        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/AssuranceAutoConducteurSansAntécédents" > <i className='fa fa-long-arrow-alt-right'></i>Assurance auto conducteur sans antécédents</Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/AssuranceAutoConducteurRésilié" > <i className='fa fa-long-arrow-alt-right'></i>Assurance auto conducteur résilié</Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/AssuranceHabitation" > <i className='fa fa-long-arrow-alt-right'></i>Assurance habitation </Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/AssuranceEmprunteur" > <i className='fa fa-long-arrow-alt-right'></i>Assurance emprunteur </Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/AutreProduitsAssurance" > <i className='fa fa-long-arrow-alt-right'></i>Autres produits d'assurance </Link></p>

        <p> <Link style={{color:"#3c5da8" , fontSize:"22px"}} to="/mutuelle" > <i className='fa fa-long-arrow-alt-right'></i> Mutuelle santé </Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/Mutuellesenior" > <i className='fa fa-long-arrow-alt-right'></i>Mutuelle senior </Link></p>
        <p> <Link style={{color:"#3c5da8" , marginLeft:"40px" , fontSize:"22px"}} to="/MutuelleTNSIndépendant" > <i className='fa fa-long-arrow-alt-right'></i>Mutuelle TNS indépendant </Link></p>

        <p> <Link style={{color:"#3c5da8"  , fontSize:"22px"}} to='/journal' > <i className='fa fa-long-arrow-alt-right'></i> Actualités</Link></p>
        <p> <Link style={{color:"#3c5da8" , fontSize:"22px"}} to="/contact" > <i className='fa fa-long-arrow-alt-right'></i> Contact </Link></p>
 
 

         </p>
      </section>
    </>
  )
}

export default Plan