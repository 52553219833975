import React from "react"


const EmprunteurTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Emprunteur : trouver un contrat d’assurance moins cher</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Lorsque vous avez souscrit un prêt à la banque pour l’achat de votre bien immobilier (maison appartement, etc.), vous avez validé le contrat d’assurance emprunteur que l’on vous proposait. Vous le trouvez désormais un peu cher et aimeriez faire jouer la concurrence pour réduire le montant de vos prélèvements mensuels.</h4>
         <p style={{padding: "20px"}}>
        
         

Vous êtes au bon endroit ! Azur Conseil, courtier en assurance emprunteur, vous offre de <a style={{color:"#3baa36"}}>trouver moins cher.</a>  
    
   
     

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default EmprunteurTitle