import React from "react"

import "./about.css"

import Title from "../common/title/Title"
import { Link } from "react-router-dom"



const AboutTitle = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
     
          <div className='title' style={{marginTop:"-20px"}}>
            <h2>CABINET AZUR CONSEIL : Assurance En Ligne Partout En France</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous ne savez pas quelle assurance choisir pour votre maison, votre crédit ou votre voiture ? Quelle mutuelle santé répond le plus à vos besoins ? A votre activité professionnelle atypique ? </h4>
         <p >
 
         Nous sommes là pour vous conseiller et vous orienter vers des partenaires adaptés à votre
          problématique et votre budget. Notre spécialité ? Assurer <Link  to="/AssuranceAutoConducteurRésilié" >les conducteurs résiliés</Link>, <Link to="/MutuelleTNSIndépendant" 
      >les indépendants </Link>au futur incertain et <Link to="/Mutuellesenior" 
   >les retraités</Link>.
         </p>
          </div>
      
        </div>
      </section>
  
    </>
  )
}

export default AboutTitle