import React, { useState } from "react"
import Back from "../back/Back"
import emailjs from 'emailjs-com';

const Result = () =>{
    return(
        <p>successfully</p>
    )
}


const DevisH = () => {
    const [result ,showResult] = useState(false)
  
    const [FName, setFName] = useState("");
    const [LName, setLName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [ville, setVille] = useState("");
    const [message, setMessage] = useState("");
    const [codepostal, setCodepostal] = useState("");
    const [motif, setmotif] = useState("");
    const [adresse, setAdresse] = useState("");



      const handleChange1 = event => {
        console.log(event.target.value);
        setmotif(event.target.value);
      };
 
 const emailContent ={
    FName: FName,
    LName: LName,
    phone: phone,
    email: email,
    ville: ville,
    codepostal: codepostal,
    adresse: adresse,

    message: message,
    motif: motif,

 };
    const sendEmail = (e) => {
        e.preventDefault();
      
        emailjs.send('service_lgygw4b','template_l3gev9t',emailContent,'vXsPUqEyTvSoTsdj_').then((result)=> {
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
    }
    setTimeout(()=>{
        showResult(false);
    }, 5000)

  return (
    <>
    
      <section className='contacts padding' style={{marginTop:"-40px"}}>
        <div className='container shadow' >
       

            <form onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Nom *' name="FName"  className="input" onChange={(event) => {setFName(event.target.value)}} required/>
                <input type='text' placeholder='Prénom *' name="LName" className="input" onChange={(event) => {setLName(event.target.value)}} required />
              </div>
              <input type='text' placeholder='Téléphone *'  name="phone"className="input" onChange={(event) => {setPhone(event.target.value)}}  required />
              <input type='email'placeholder='Email *'  name="email" className="input" onChange={(event) => {setEmail(event.target.value)}}  required  />
              <input type='text'placeholder='Adresse *'  name="adresse"className="input"  onChange={(event) => {setAdresse(event.target.value)}} required  />
              <div className='flexSB'>
                <input type='text' placeholder='Code postal *' name="codepostal" className="input" onChange={(event) => {setCodepostal(event.target.value)}} required />
                <input type='text' placeholder='Ville *' name="ville" className="input" onChange={(event) => {setVille(event.target.value)}} required />
              </div>
              <div className='flexSB'>
<p>Motif de résiliation * :</p>
<select name='motif' onChange={handleChange1} style={{width:"650px"}} >
<option value='Faites votre choix'>Faites votre choix</option>
  <option value='Non paiement'>Non paiement</option>
  <option value="Sinistres">Sinistres</option>

  
</select></div>

              <textarea type='text' placeholder='Des précisions ?' name="message"  onChange={(event) => {setMessage(event.target.value)}}  required />
            
              <button className='primary-btn'>ENVOYER</button>
            </form>
<div>{result ? <Result/> : null }</div>
    
          </div>
      
      </section>
    </>
  )
}

export default DevisH