import React from "react"
import { Link } from "react-router-dom"


import Title from "../common/title/Title"

import './Accueil.css'
import { posthog } from "posthog-js"
import CookieBanner from "../CookiesBanner"
const Accueil = () => {
  return (
    <>
      <section className='AssuranceHome' style={{ marginTop: "20px"}}  >
        <div className='container ' >
   
        <div className='title'>
          <h4 style={{ marginBottom:"-50px",fontSize:"20px"}}>AZUR CONSEIL - COURTIER EN ASSURANCE ET MUTUELLE SANTE</h4>
            <h2 >Assurance et mutuelle santé: le courtage pour tous</h2>
            <br/>
            <hr class="solid"/>
           
         <p >
        
         Il est parfois difficile de trouver la bonne assurance, la bonne mutuelle santé… En particulier lorsque l’on a eu une interruption d’assurance ou lorsqu’on est résilié pour non-paiement, fréquence de sinistre, annulation ou suspension de permis pour cause d’alcoolémie ou autre.

 
 

         </p>
         <p><span style={{color:"#ee8a00" , fontWeight:"600"}} >Chez Azur Conseil, nous accompagnons tout le monde, particuliers et professionnels, dans les bons moments comme dans les coups durs.</span> Notre cabinet de courtage trouve la solution qui vous convient quels que soient votre profil et votre situation !</p>
       
          </div>
       
      
        </div>
        <div class="container">
    <div class="cards grid-row">
      <div class="cardf">
        <div class="card-top">
         
        </div>
        <div class="card-info">
    
          <p class="excerpt">Un service en ligne et de proximité, pour vous offrir le meilleur !</p>
        </div>
   
      </div>
      
    </div>   
    <div class="cont" style={{marginTop:"50px"}}>
		<div class="items">
			<div class="photo">
				<img src="./images/home.jpg" alt="assurance" className="img1"/>
	        </div>
	        <div class="leftH" id="full">
	       
           
            </div>
		</div>
		<div class="rightH">
        <h2>
        Des solutions pour profil à risque aggravé</h2>
        <p >  <br/> <p ><a style={{color:"#3baa36" , fontWeight:"500"}}>Non-paiement, sinistres à répétition, suspension ou annulation de permis pour cause d’alcoolémie ou d’usage de stupéfiant, radiation, malussé occasionnel ou en récidive :</a> autant de raisons qui vous freinent pour contracter une nouvelle  <Link to="/AssuranceAutoConducteurRésilié"> assurance auto</Link> ou <Link to="/AssuranceHabitation">  habitation</Link>.
      <br/>    <br/>   Toutefois, vous ne pouvez pas faire l’impasse, sous peine d’une lourde amende.
<br/>   <br/> En tant que pro du courtage en  <Link to="/assurance"> assurance </Link>et <Link to="/mutuelle">  mutuelle santé </Link>, <span style={{ fontWeight:"500"}} >nous proposons des solutions et des garanties aux conducteurs et assurés avec profils à risque aggravé.</span> Parce que tout le monde a le droit à l’erreur et à la protection !
</p></p><br/>
<Link to="/about">
<button className='phone-orange' style={{padding: "20px", marginBottom: "20px"}}>
       DÉCOUVRIR LE CABINET DE COURTAGE<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
		</div> 
	</div> 
 
  </div>
  <div class="conta" style={{marginTop:"50px", marginLeft:"60px"}}>
		<div class="items">
            <h5 style={{color:"#3BAA36",fontSize:"25px",marginLeft:"350px"}}>INSCRIPTION</h5>
        <h1 style={{color:"#3c5da8" ,marginLeft:"250px"}}>Votre devis en ligne</h1>
        <h1 style={{color:"#3c5da8", fontWeight:"800", marginLeft:"280px"}}>rapide et gratuit !</h1>
        <p style={{fontSize:"25px"}}>Pour obtenir votre devis d'assurance ou de mutuelle santé, c'est par ici :</p>
        <Link to="/devis">
        <button className='btn-vert'>
        MON DEVIS PERSONNALISÉ <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
		</div>
	
	</div>
    <section className='partenair'>
        <div className='container flexSB'>
          <div className='left row'>
          <span>NOS DIFFÉRENTS</span>
            <h1>Partenaires</h1>

          </div>
          <div className='right row'>
          <img src="./images/l1.png" alt="" style={{width:"150px", height: "50px"}} />
          </div>
          <div className='right row k'>
          <img src="./images/l2.png" alt="" style={{width:"150px", height: "50px"}}   />
          </div>
          <div className='right row k'>
          <img src="./images/l3.png" alt="" style={{width:"150px", height: "50px"}}   />
          </div>
          
        </div>
        <div className='container flexSB'>
    
          <div className='right row k1'>
          <img src="./images/l2.png" alt="" style={{width:"150px", height: "50px"}}   />
          </div>
          <div className='right row k1'>
          <img src="./images/l3.png" alt="" style={{width:"150px", height: "50px"}}   />
          </div>
          
        </div>
      </section>

      </section>

  {posthog.has_opted_in_capturing()|| posthog.has_opted_out_capturing()? null:<CookieBanner/>}
    </>
  )
}

export default Accueil