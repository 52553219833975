import React from "react"
import { Link } from "react-router-dom"
import { blog } from "../data/Data"


const BlogCard = () => {

  return (
    <>
      {blog.slice(0, 3).map((val) => (
        <div className='items shadow'>
          <div className='img'>
            <img src={val.cover} alt='blog' />
          </div>
          <div className='textblog'>
            <div className='admin flexSB'>
        
              <span>
                <i className='fa fa-calendar-alt'></i>
                <label htmlFor=''>{val.date}</label>
              </span>
       
            </div>
            <h2>{val.title}</h2>
            <p>{val.desc}</p>
          
            <Link to={`/blog/${val.id}`}>
                <button className='btn-blog'>
        EN SAVOIR PLUS<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
        </div>
      ))}
    </>
  )
}

export default BlogCard