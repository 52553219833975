import React, { useState } from "react"
import Back from "../back/Back"
import emailjs from 'emailjs-com';

import "./contact.css"
const Result = () =>{
    return(
        <p>successfully</p>
    )
}


const Contact = () => {
    const [result ,showResult] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_lgygw4b','template_xc74b32',e.target,'vXsPUqEyTvSoTsdj_').then((result)=> {
            console.log(result.text);
        },(error) =>{
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
    }
    setTimeout(()=>{
        showResult(false);
    }, 5000)

  const map ="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23092.26500686803!2d7.160215!3d43.657881!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cdd3eb7897d6a7%3A0x3cd2a371fe4023ed!2sAzur%20Secr%C3%A9tariat%20Services!5e0!3m2!1sfr!2sfr!4v1679354351432!5m2!1sfr!2sfr" 
  return (
    <>
      <Back title='Contact' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Votre devis gratuit</h1>
            <p>Pour nous contacter, merci de bien vouloir remplir le formulaire suivant. Notre équipe s'engage à vous répondre dans les plus brefs délais.</p>
            <br/><div className='box adress' >
                <h4>ADDRESS:</h4>
                <p>60 avenue de Nice
06800
Cagnes-sur-Mer
FRANCE</p>
              </div>
            <div className='items grid2'>
             
              <div className='box' style={{width:"200px"}}>
                <h4>EMAIL:</h4>
                <p> contact@azur-conseil.fr</p>
              </div>
              <div className='box' style={{width:"200px"}}>
                <h4>PHONE:</h4>
                <p>09 88 77 04 88</p>
              </div>
            </div>

            <form onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Nom *'className="input" name="FName" required/>
                <input type='text' placeholder='Prénom *' className="input" name="LName" required />
              </div>
              <input type='text' placeholder='Téléphone *' className="input" name="phone"  required />
              <input type='email'placeholder='Email *' className="input" name="email" required  />
              <div className='flexSB'>
                <input type='text' placeholder='Code postal *' className="input" name="codepostal" required />
                <input type='text' placeholder='Ville' name="ville" className="input" required />
              </div>
              <textarea type='text' placeholder='Votre demande *' name="message" required />
            
              <button className='primary-btn'>ENVOYER</button>
            </form>
<div>{result ? <Result/> : null }</div>
          
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact