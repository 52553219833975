import React from 'react'
import Back from '../back/Back'
import MotoCard from './MotoCard'
import MotoTitle from './MotoTitle'






const Moto =()=> {
    return(<>

    <Back title='ASSURANCE MOTO SCOOTER' />
    <MotoTitle/>
 <MotoCard/>

 

    </>)
}
export default Moto