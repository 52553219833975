import React from "react"
import { Link } from "react-router-dom"

import "./about.css"



const AboutCard = () => {
  return (
    <>
      <section className='aboutHome'>
        <div className='container flexSB'>
          <div className='left row'>
            <img src='./images/azur conceil.jpg' alt='Cabinet Azur Conseil' />
          </div>
          <div className='right row'>
            <br/>
      <h2>Cabinet Azur Conseil : comparateur d’assurance en ligne</h2>
         
            <p> <br/> <p style={{fontWeight: "600"}}>Notre métier de courtier en assurance est avant tout d’être à votre écoute et de vous guider.</p><p>Vous ne savez pas <Link 
    to="./ConducteurMalussé">comment 
              assurer votre voiture avec un malus conséquent</Link> ? Vous êtes<Link to="./Freelance"> graphiste freelance et les complémentaires santé </Link>vous boudent ? Votre 
              assurance habitation a été résiliée pour non-paiement ? Vous pouvez compter sur le Cabinet Azur Conseil, nos experts répondent à vos
               interrogations !<br/>Nous recherchons et comparons pour vous les meilleurs contrats, sans que vous ayez à en assumer le stress. Ce qui 
               n’est pas négligeable si votre dossier est complexe ou que vous faites partie <Link to="./AssuranceMotoScooter" >  des profils dits à risque aggravé…</Link></p></p>
            </div>
          </div>
          <div className='container flexSB'>
          <div className='right1 row'>
      <h2>Une offre personnalisée et ciblée</h2>
         
            <p> <br/> L’éventail de<a > partenaires de confiance</a> avec lesquels notre cabinet travaille nous 
            permet de vous proposer une couverture adaptée à vos besoins et à votre budget, en termes <Link to="/assurance">d’assurance</Link> 
            et de<Link to='/mutuelle'> mutuelle santé</Link>.</p>
            <div className="box">
                            
                                    <div className="text">
                    
                                        <p>Au Cabinet Azur Conseil, nous vous proposons une prise en charge totale : nous nous occupons de tout jusqu’à l’obtention d’une proposition intéressante pour vous. <a style={{color:"#3baa36"}}>Nous faisons office de comparateur
                                             d’assurance</a>, le sourire et le soutien en plus !</p></div></div>
                                             <Link to="/professionnelle">
                                             <button className='btn'>
          DÉCOUVRIR NOTRE OFFRE D'ASSURANCE EN LIGNE POUR LES PROFESSIONNELS <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
            </div>
          <div className='left1 row' >
            <img src='./images/vieux.jpg' alt='Cabinet Azur Conseil' />
          </div>

          </div>
        
          <div className='container flexSB'>
          <div className='title'>
            
          <hr class="solidDivider" style={{marginTop: "10px"}}/><br/>
            <h4>Vous souhaitez évoquer votre problématique ? Comparer plusieurs offres de contrat d’assurance en ligne ? N’hésitez pas à nous contacter !</h4>
  
         <p style={{padding: "20px"}}>
         Notre équipe est implantée en France, à Cagne sur Mer (Alpes Maritimes).
Vous pouvez échanger avec un conseiller par téléphone ou via ce site internet, quel que soit votre lieu d’habitation.
         </p>
         <Link to="/contact">
         <button className='btn-orange'>
          DÉCOUVRIR NOTRE OFFRE D'ASSURANCE EN LIGNE POUR LES PROFESSIONNELS <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>

          </div>
  
      </section>

    </>
  )
}

export default AboutCard