import React from "react"


const AutreProduitTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Nos autres produits d’assurance : protection juridique, garantie obsèques, prévoyance, chien et chat</h2>
            <hr class="solid"/>
            
         <p style={{padding: "20px"}}>
         Les produits d’assurance que nous vous proposons de trouver au plus proche de vos besoins peuvent parfois être complétés par des options. Si elles peuvent paraître secondaires, elles sont souvent très utiles, comme<a style={{color:"#3baa36"}}>la protection juridique vie privée,</a> la <a style={{color:"#3baa36"}}>garantie obsèques</a>  ou la <a style={{color:"#3baa36"}}>prévoyance </a>.
      <br/> Azur Conseil vous guide et vous conseille pour choisir les  <a style={{color:"#3baa36"}}>meilleures garanties</a>. 
    
   
     

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default AutreProduitTitle