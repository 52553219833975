import React from 'react'
import './header.css'
import { Link } from 'react-router-dom'
const Head = () => {
  return (
    <>
    <section className='head'>
      <div className='container flexSB'>
        <div className='logo'>
        <img src="./images/Logo.png" alt="azur conseil" />
        </div>

        <div className='social' style={{marginRight: "-32px"}}>
        <button class="button-33" role="button"> <Link to='/etreAppeler' >09 88 77 04 88 <i class="fa-solid fa-phone"></i></Link></button>
          <i className='fab fa-facebook-f icon'></i>
  
        
        </div>
      </div>
    </section>
  </>
  )
}
export default Head