import React from 'react'
import ConducteurTitle from './ConducteurTitle'


import BackM from './Back'
import ConducteurCard from './ConducteurCard'


const ConducteurMalussé =()=> {
    return(<>

    <BackM title='Conducteur malussé : comment assurer sa voiture ?' />
  <ConducteurTitle/>
  <ConducteurCard/>
  

    </>)
}
export default ConducteurMalussé