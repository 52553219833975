import React from "react"

import "./assurance.css"





const AssuranceTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Assurance et garanties : faire le bon choix</h2>
            <hr class="solid"/>
         <p style={{padding: "20px"}}>
         Un contrat d’assurance est toujours complexe, chaque compagnie ayant ses propres termes, ses propres offres, ses propres garanties.<br/>
         Azur Conseil vous accompagne et vous guide dans le choix de l’assurance qui vous conviendra le mieux, que vous soyez <a style={{color:"#3baa36"}}>nouveau assuré</a>  ou que vous souhaitiez <a style={{color:"#3baa36"}}>changer de contrat</a>.
         </p>
          </div>
      
        </div>
      </section>
  
    </>
  )
}

export default AssuranceTitle