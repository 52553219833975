import React from 'react'
import Back from '../back/Back'
import Back5 from '../back/Back4'
import FreelanceCard from './FreelanceCard'
import FreelanceTitle from './FreelanceTitle'


const Freelance =()=> {
    return(<>

    <Back5 title='FREELANCE ET COMPLÉMENTAIRE SANTÉ' />
    <FreelanceTitle/>
    <FreelanceCard/>


    </>)
}
export default Freelance