import React from "react"


import "./conducteurMalussé.css"
import { Link } from "react-router-dom"




const ConducteurTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Conducteur malussé : comment assurer sa voiture ?</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous enchaînez les accidents avec votre voiture ? Votre malus atteint des sommets et vous souhaitez trouver un contrat d’assurance moins cher ?</h4>
         <p style={{padding: "20px"}}>
         Vous pouvez trouver une assurance qui répond à vos besoins grâce au <Link to="/about">Cabinet Azur Conseil.</Link>


         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default ConducteurTitle