import React from "react"


const MutuelleSeniorTitle = () => {
  return (
    <>
      <section className='AssuranceHome'>
        <div className='container flexSB'>
     
          <div className='title'>
            <h2>Mutuelle senior : comparer et trouver une couverture adaptée</h2>
            <hr class="solid"/>
            <br/>
            <h4 style={{fontSize:"18px"}}>Vous êtes désormais considéré comme un senior, vos dépenses de santé augmentent... Vous avez peut-être anticipé après avoir soufflé vos cinquante bougies et adhéré à une complémentaire santé adaptée. Mais si ce n’est pas le cas, il est temps de trouver une mutuelle senior qui répond à vos besoins.</h4>
         <p style={{padding: "20px"}}>
        
         Comment faire ? En faisant confiance à un courtier, qui saura <a style={{color:"#3baa36"}}>  comparer et vous proposer une solution personnalisée. </a>Azur Conseil, spécialisé en mutuelle senior et profil à risque, est à votre écoute.

 
 

         </p>
          </div>
      
        </div>
        
      </section>
  
    </>
  )
}

export default MutuelleSeniorTitle