import React from "react"
import { Link } from "react-router-dom"
import DevisE from "./DevisE"





const EmprunteurCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/emprunteur.jpg' alt='Assurance emprunteur' />
          </div>
          <div className='right1 row'>
      <h2>Assurance emprunteur : comment en changer en cours de prêt immobilier</h2>
         
            <p> <br/> <p>Que vous souhaitiez trouver un contrat d’assurance emprunteur moins cher ou bénéficier de davantage de garanties pour le même tarif, vous pouvez changer de contrat selon les modalités suivantes :
<br/>
<p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Votre prêt immobilier a été conclu il y a moins d’un an :  </span> vous devez soumettre votre nouveau contrat d’assurance emprunteur à votre assureur 15 jours avant échéance.</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Votre prêt immobilier à plus d’un an : </span>  vous devez effectuer les démarches de changement 2 mois avant la date anniversaire de la signature du prêt.</p>

</p></p>
<div className="box">
                            
                            <div className="text">
            
                                <p>Dans les deux cas, votre banque ne vous facturera aucun frais. La loi Hamon et l’amendement Bourquin vous garantissent de pouvoir changer de contrat d’assurance emprunteur en toute sécurité.
                         </p></div></div>
            </div>
          </div>
          <div className='container'>
        
          <h2>Pourquoi faire appel à un courtier pour son assurance emprunteur</h2>

  <br/>

<p >Si changer de contrat d’assurance de prêt immobilier pour un moins cher est une chose courante, les démarches n’en demeurent pas moins complexes (comme pour <Link to="/assurance">tous les produits d’assurance</Link> ).
Un courtier, pouvant intervenir en ligne et en direct par téléphone, vous permet de<a style={{color:"#3baa36"}}>trouver une assurance de prêt offrant des garanties intéressantes, adaptées à vos besoins et votre situation. </a> Il analyse pour vous les différentes possibilités et sélectionne les meilleures.
 </p>
 <div className="box">
                            
                            <div className="text">
            
                                <p>Pour ne pas perdre de temps, faites confiance à Azur Conseil, courtier spécialisé en assurance emprunteur !
                         </p></div></div>
                         <p >Notre cabinet s'occupe de toutes les démarches nécessaires auprès des organismes emprunteur pour la mise en place de votre contrat : votre questionnaire de santé vous est adressé par mail en toute confidentialité et 
                         <a style={{color:"#3baa36"}}>   nous nous chargeons de l'envoi de votre courrier de résiliation auprès de votre assureur actuel.</a>
 </p>
                               </div>

                               <div className='container'>
       <br/>
      
       <h2>Demandez votre devis assurance emprunteur</h2>
<DevisE/>
</div>





                               <div className='container '>
  
          <div className='title' style={{marginTop:"0px"}}>
          <hr class="solidDivider" style={{marginTop: "10px"}}/>   
               <br/>
            <p>Notre équipe d'expert sera à votre disposition par téléphone ou par par mail afin de répondre à toutes vos attentes. N’hésitez pas à nous contacter, nos experts, implantés en France (à Cagne sur Mer dans les Alpes Maritimes), sont à votre disposition.
            <br/><br/>    </p>
            
         <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE COURTIER EN ASSURANCE EMPRUNTEUR<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
    
      </section>

    </>
  )
}

export default EmprunteurCard