import React from 'react'
import Accueil from './Accueil';
import AccueilTitle from './AccueilTitle';
import AboutCard from './AccueilTitle';
import HAbout from './AccueilTitle';
import BlogTitle from './BlogAccueil';

import Hero from './hero/Hero';



const Home =()=>{
    return (
        <>
        <Hero/>
<AccueilTitle/>
<Accueil/>
<BlogTitle/>
        </>
 
      
    )
}
export default Home;