import React from "react"
import { Link } from "react-router-dom"

import "./mutuelle.css"
import MutuelleService from "./MutuelleService"



const MutuelleCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/mutuelle.webp' alt='Cabinet Azur Conseil' />
          </div>
          <div className='right1 row'>
      <h2>Cabinet Azur Conseil : comparateur d’assurance en ligne</h2>
         
            <p> <br/> <p >Une mutuelle santé ou complémentaire santé rembourse à l’assuré les frais médicaux
             non pris en charge par la Sécurité Sociale. Elle n’est pas obligatoire mais vous permet de ne pas subir un reste à charge parfois très élevé,
             comme dans le cas d’une intervention chirurgicale, dentaire ou un suivi orthodontique.
             <br/>Les contrats et niveaux de remboursement varient d’une compagnie à une autre : c’est pourquoi il est important de souscrire à une mutuelle dont le niveau de garantie correspond à votre propre situation et à vos besoins.</p></p>
             <h4 style={{color: "#3baa36"}}><Link to="/Mutuellesenior">Seniors </Link> et <Link to="/MutuelleTNSIndépendant"> travailleurs non salariés </Link>devront par exemple choisir une complémentaire santé spécifique.</h4>
            </div>
          </div>
          <div className='container'>
       
       <h3 style={{marginTop: "30px"}}>La mutuelle santé d’entreprise</h3> 
  

<p >Obligatoire depuis le 1er janvier 2016, elle est proposée à chacun des salariés par l’employeur, qui participe au paiement de la cotisation à hauteur de 50 %.</p>
<div className="boxAssu">
                           
                           <div className="textAssu">
           
                               <h3>Les garanties possibles:</h3>
                               <div className='container flexSB'>
                               <div className='left1 row'>
                             
                               <div class="list-type3 ">
<ol>
<li><a href="#">Consultation médicale</a></li>
<li><a href="#">Médicaments prescrits</a></li>
<li><a href="#">Frais d’optique, dentaires, auditifs</a></li>

</ol>
</div>
         </div>
         <div className='right row'>            
           
<div class="list-type3">
<ol>
<li><a href="#">Examens médicaux et analyses</a></li>
<li><a href="#">Frais d’hospitalisation</a></li>
<li><a href="#">Etc.</a></li>
</ol>
</div>
</div>
</div>
                               
 
                               
                               </div></div>   </div>
                               <div className='container '>
  
          <div  style={{marginTop:"0px"}}>
          <h2>Pourquoi faire appel à un courtier en mutuelle santé ?</h2>
  
         <p style={{padding: "20px"}}>
         Un courtier en mutuelle santé comme <Link to="/about">Azur Conseil</Link> travaille avec des assureurs partenaires mais reste indépendant et offre un œil expert indispensable lors du choix de la meilleure complémentaire.

 <br/>

 <a style={{color:"#3baa36"}}>Un courtier en mutuelle santé s’occupe de tout de A à Z, afin de vous faire gagner du temps</a>. Vous n’aurez pas à vous occuper des recherches fastidieuses et des démarches administratives liées à la souscription d’une nouvelle complémentaire !
         </p>
         <div className="box">
                            
                            <div className="text">
            
                                <p>Vous souhaitez changer de complémentaire santé pour une moins chère ? En tant que courtier, Azur Conseil vous accompagne dans<a style={{color:"#3baa36"}}> le choix de votre complémentaire et se charge des modalités de résiliation auprès de votre assureur actuel.</a>
                         </p></div></div>
                         <hr class="solidDivider"/>
                     <br/>
                         <div className='title'>
            <p>Envie d’en savoir plus ? De mieux nous connaitre avant de faire appel à Azur Conseil pour votre mutuelle santé ?

Nos conseillers sont à votre écoute depuis notre siège de Cagne sur Mer dans les Alpes Maritimes (06), par téléphone ou via notre site internet.<br/><br/>
     </p>
     <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE EXEPERT EN ASSURANCE PROFESSIONNELLE <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div></div>
    
          </div>
          <MutuelleService/>
      </section>

    </>
  )
}

export default MutuelleCard