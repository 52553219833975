import React from "react"
import { Link } from "react-router-dom"
import Devis from "./Devis"




const ResilieCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/resilie.jpg' alt='Assurance auto conducteur résilié ' />
          </div>
          <div className='right1 row'>
      <h2>Pourquoi un conducteur peut-il être résilié ?</h2>
         
            <p >  <br/> <p >Votre compagnie d’assurance peut décider de vous résilier pour diverses raisons (et ce pour<Link to="/assurance"> tous les produits d’assurance </Link>) :
</p></p>
<p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Vous avez de nombreux accidents responsables </span>  à votre actif et <Link to="/ConducteurMalussé">vous avez un malus élevé,</Link></p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Vous avez été verbalisé, </span>   avez perdu votre permis pour usage de stupéfiant et / ou alcoolémie élevée,</p>
                      <p  ><i class="fas fa-caret-right"></i> <span style={{color:"#000000"}}>Vous avez été en situation de non-paiement  </span>de votre cotisation.</p>
           
                      <div className="box">
                            
                            <div className="text">
            
                                <p>Si vous êtes prévenu à l’avance de la fin de votre contrat par courrier recommandé avec accusé réception, vous devez commencer dès à présent à rechercher une nouvelle assurance qui acceptera un conducteur résilié et doté d’un malus important.</p></div></div>
                                 </div>
          </div>
          <div className='container'>
       
      
          <h2>Faire appel à un courtier pour trouver une assurance auto conducteur résilié</h2>

<p style={{fontWeight: "500", fontSize:"15px"}}><br/>Seul un courtier en assurance, disponible en ligne et sur le terrain, est à même de vous proposer un panel de solutions correspondant à vos contraintes, à votre malus et au meilleur tarif.<br/>
<a style={{color:"#3baa36"}}>Azur Conseil est spécialisé en assurance auto pour conducteur avec profil à risque.</a> Après une étude approfondie, nous vous proposons un contrat adapté à votre situation de conducteur résilié, incluant des garanties intéressantes et un prix attractif.

 </p>
            
 <div className="box">
                            
                            <div className="text">
            
                                <p>Attention : Ne cédez pas à la tentation de rouler sans assurance auto. La loi est très stricte : gendarmes et policiers de la route effectuent régulièrement des contrôles.</p></div></div>
  </div>

  <div className='container'>
       <br/>
      
       <h2>Demandez votre devis auto conducteur résilié</h2>
<Devis/>
</div>










                               <div className='container '>
                               <hr class="solidDivider" style={{marginTop: "10px"}}/>
  
          <div className='title' style={{marginTop:"0px"}}>
       
  
         <p style={{padding: "20px"}}>
         Depuis son siège social (Cagne sur Mer dans les Alpes Maritimes), Azur Conseil est à votre écoute par téléphone ou via notre site internet.
         </p>
  
         <Link to="/contact">         
    
         <button className='btn-orange'>
        CONTACTER VOTRE SPÉCIALISTE ASSURANCE AUTO CONDUCTEUR RÉSILIÉ <i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
        
      </section>

    </>
  )
}

export default ResilieCard