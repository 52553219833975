import React from "react"
import { Link } from "react-router-dom"




const IndependantCard = () => {
  return (
    <>
      <section className='MutuelleHome'>
        <div className='container flexSBf'>
          <div className='left row'>
            <img src='./images/traval.jpg' alt='Mutuelle TNS et travailleur indépendant' />
          </div>
          <div className='right1 row'>
      <h2>
      Qu’est-ce qu’une bonne mutuelle TNS et travailleur indépendant ?</h2>
         
            <p >  <br/> <p >Artisan, commerçant, profession libérale, etc. n’ont pas la même activité ni le même régime obligatoire, c’est pourquoi leur couverture sociale diffère, rendant le choix de la mutuelle santé complexe.
      <br/>    <br/>   <a style={{color:"#3baa36" , fontWeight:"500"}}> Une bonne mutuelle TNS et travailleur indépendant est donc une mutuelle qui répond aux exigences de votre profession. </a>
<br/>   <br/> Le prix d’une mutuelle santé variant avec le niveau de remboursement choisi, il est essentiel de définir vos besoins en termes de frais médicaux (dentaire, optique, etc.), selon votre âge et votre situation de famille.
</p></p>

           

                                 </div>
          </div>
          <div className='container'>
       <br/>
      
          <h2>LE CONTRAT MADELIN : COUP DE POUCE AUX TNS ET TRAVAILLEURS INDÉPENDANTS</h2>

<p ><br/>
Afin que les indépendants souscrivent une mutuelle santé, la loi Madelin leur permet de déduire de leurs impôts le montant de la cotisation.</p>
                  

 <div className="box">
                            
                            <div className="text">
            
                                <p> Tous les aspects touchant à la protection sociale sont complexes : <Link to="/about"> Azur Conseil, en tant que courtier en assurance TNS,</Link> met ses connaissances de la réglementation et du marché des complémentaires au service de votre santé !
                         </p></div></div>
                         <Link to="/Freelance"> 
                         <button className='btn-orange'>
        EN SAVOIR PLUS SUR LA COMPLÉMENTAAIRE SENTÉ POUR FREELANCE<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>

  </div>
                               <div className='container '>
                                <br/>
  
          <div className='title' style={{marginTop:"0px"}}>
          <hr class="solidDivider"/>
       
         <p style={{padding: "20px"}}>
         Nos conseillers et experts sont basés en France, à Cagne sur Mer dans les Alpes Maritimes (06). Nous vous proposons un rendez-vous téléphonique pour mieux vous connaitre puis comparons pour vous<Link to="/mutuelle"> tous les contrats de mutuelle santé </Link>. Inutile de vous arracher les cheveux pour trouver la meilleure mutuelle TNS, nous vous l’apportons sur un plateau !
         </p>
  
                     
         <Link to="/contact">  
         <button className='btn-orange'>
        CONTACTER VOTRE EXPERT MUTUELLE TNS ET TRAVAILLEUR INDÉPENDANT POUR EN SAVOIR PLUS !<i className='fa fa-long-arrow-alt-right'></i>
              </button></Link>
          </div>
    
          </div>
        
      </section>

    </>
  )
}

export default IndependantCard